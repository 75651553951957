import React from 'react';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import {Tooltip} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";

export default function DescriptionTooltip(props) {

    const { item } = props;

    const [open, setOpen] = React.useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <>
        {item.information !== "" &&
                <ClickAwayListener onClickAway={handleTooltipClose}>
                    <Tooltip
                        PopperProps={{
                            disablePortal: true,
                        }}
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={item.information}
                    >
                        <ListItemIcon
                            edge="end"
                            aria-label="add"
                            value={item}
                            onClick={handleTooltipOpen}
                            sx={{p: 1}}
                        >
                            <IconButton edge={"end"}>
                                <InfoIcon color={"info"}/>
                            </IconButton>
                        </ListItemIcon>
                    </Tooltip>
                </ClickAwayListener>
        }
        </>
    );
}