import React from "react";
import "../App.css";
import {Helmet} from "react-helmet";
import {Container} from "@mui/material";
import Ads from "../components/Ads";
import HowToUse from "../components/HowToUse";
import {SnackbarProvider} from "notistack";
import CategoryCards from "../components/CategoryCards";
import InsightsHomepage from "../components/InsightsHomepage";
import SubscriptionsBanner from "../components/SubscriptionsBanner";
import {CalculatorBanner} from "../components/CalculatorBanner";
// import Recommendations from "../components/Recommendations";

export function Home(props) {
  const {
    subscriptionsData,
    categories,
  } = props;

  return (
      <SnackbarProvider maxSnack={4} autoHideDuration={1500}>
      <div className="App">
        <Helmet>
          <title>Subscribn. - Manage, track and budget your subscriptions</title>
          <meta name="description" content="Finding subscriptions that align with your specific needs can often be a
          laborious task, consuming valuable time and effort. However, Subscribn presents an ideal solution by offering
          an extensive range of subscriptions tailored to your budgetary constraints and personal interests. With
          Subscribn, you gain the ability to effectively manage your subscriptions, empowering you to allocate your
          financial resources more efficiently. Additionally, Subscribn provides valuable insights into the costs
          associated with your subscriptions, allowing you to identify and evaluate which ones are placing the greatest
          strain on your budget. By conveniently presenting all available subscriptions in the UK, organized by category
          and price, Subscribn enables you to access a comprehensive list that precisely matches your preferences.
          Furthermore, in a testament to our commitment to meeting your needs, we even include a collection of free
          subscriptions for you to explore and enjoy." />
        </Helmet>
        <CalculatorBanner />
        <br />
        <Container>
          <CategoryCards categories={categories} subscriptionsData={subscriptionsData} />
        </Container>
        <br />
          <SubscriptionsBanner subscriptionsData={subscriptionsData} />
        <br/>
          <HowToUse />
        <br />
          <InsightsHomepage />
        <br />
          <Ads />
        <br />
      </div>
    </SnackbarProvider>);
}

export default React.memo(Home);