import React from 'react';

import {Container, Step, StepContent, StepLabel, Stepper, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";


export function HowToUse() {

    const [activeStep, setActiveStep] = React.useState(0);

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const steps = [
        {
            label: 'Select a budget',
            description: `After navigating to the subscriptions calculator, using the slider drag the circle to a budget that fits your criteria, or alternatively,
                    use the text input box to set a maximum budget, and then drag the slider to play around with your
                    budget. You should see the total amount you have spent so far just below the subscriptions list.`,
        },
        {
            label: 'Select some categories',
            description: `Just below the slider are a few buttons labelled by category. Click
                    or tap on a category box to select it; this will trigger subscriptions that match the categories
                    you have selected to show up in the subscriptions list below. Unclick or untap at any time to filter
                    out that category.`,
        },
        {
            label: 'Add subscriptions',
            description: `Below the category boxes, you should see a box that will initially show all subscriptions. By selecting a budget
                    and some categories, you will be presented with a list of filtered subscriptions based on your 
                    selections.
                    On the right side of each subscription, you should see a + icon. Click or tap this icon, and the
                    subscription will be added to your list. You can view this list at any time by either clicking
                    "Subscriptions" in the navigation bar or by selecting the "view selected" button below
                    the subscriptions box.
                    You can also view how much of your budget has been used up by looking at the progress bar below the
                    subscriptions box. This will increase slowly as you add more subscriptions, and will warn you when you
                    you exceed your budget.`,
        },
        {
            label: 'View insights',
            description: `You may want some insightful information based on the subscriptions you have selected. 
                    In order to do this, you can click the "Insights" button just below the how to use section or in the navigation bar. This should take you
                    to a page where you can see some interesting information that can help you budget slightly better.
                    Make sure to select more than three subscriptions to see better insights!`,
        },
        {
            label: 'Remove subscriptions',
            description: `You may not be happy with some of the subscriptions you have chosen. In order to remove them you can
                    click on the "Subscriptions" button in the navigation bar which should bring up a drawer. In this drawer you will
                    be able to remove any subscriptions. You could also navigate to the "my subscriptions" page by clicking the 
                    "view all" button, which gives you a full list of all of your subscriptions which you can then remove accordingly.
                    Lastly you could click the "start over" button which resets everything.`,
        },
    ];

    return (
        <Box
            sx={{
                backgroundColor: 'rgba(52,192,230,0.47)',
                textAlign: 'left'
            }}
        >
            <Container>
                <Typography id="learntouse" variant={"h4"} component="h3" sx={{paddingTop: 4, paddingBottom: 4, fontWeight: 'bold'}}>
                    Follow the steps to learn how to use Subscribn
                </Typography>
                <Divider variant="middle" />
                <br/>
                <Stepper activeStep={activeStep} orientation="vertical">
                    {steps.map((step, index) => (
                        <Step key={step.label}>
                            <StepLabel
                                optional={
                                    index === (steps.length - 1) ? (
                                        <Typography variant="caption">Last step</Typography>
                                    ) : null
                                }
                            >
                                {step.label}
                            </StepLabel>
                            <StepContent TransitionProps={{ unmountOnExit: false }}>
                                <Typography>{step.description}</Typography>
                                <Box sx={{ mb: 2 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            {index === steps.length - 1 ? 'Finish' : 'Continue'}
                                        </Button>
                                        <Button
                                            disabled={index === 0}
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                        >
                                            Back
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} sx={{ p: 3 }}>
                        <Typography>All steps completed - Enjoy using Subscribn.</Typography>
                        <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
                            Reset
                        </Button>
                    </Paper>
                )}
                <br/>
            </Container>
        </Box>
    );
}

export default React.memo(HowToUse);