import React from "react";
import {Link} from "react-router-dom";
import {styled} from "@mui/styles";
import Marquee from "react-fast-marquee";

export function SubscriptionsBanner(props) {

    const {subscriptionsData, pauseOnHover = false} = props;

    const BannerImage = styled('img')(() => ({
        maxWidth: '150px',
        height: '5rem',
        padding: '0 15px',
        "img:last-of-type": {
            paddingLeft: 0
        },
        objectFit: "contain"
    }));

    const randomisedSubs = [...subscriptionsData].sort(() => Math.random() - 0.5);

    return (
        <>
        <br />
              <Marquee pauseOnHover={pauseOnHover}>
                {randomisedSubs.map(subscription => (
                    <div className="image" key={subscription.id}>
                        <Link to={`/subscription/${subscription.name.replace(/\s/g, "")}`} >
                            <BannerImage loading="lazy" src={subscription.logo} alt={subscription.name} />
                        </Link>
                    </div>
                ))}
              </Marquee>
        <br />
        </>
    );
}

export default React.memo(SubscriptionsBanner);