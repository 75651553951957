import React from 'react';
import { makeStyles } from '@mui/styles';
import Grid from "@mui/material/Grid";
import {Box, Container, Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";


export default function LoadingScreen() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '65vh'
            }}
        >
            <Container>
                <Grid container justifyContent="center" alignItems="center" spacing={1} direction="column">
                    <Grid item>
                        <Container maxWidth="md">
                            <CircularProgress />
                        </Container>
                    </Grid>
                    <Grid item>
                        <Typography
                                    noWrap
                                    sx={{
                                        mr: 2,
                                        fontFamily: '"Helvetica Neue"',
                                        fontWeight: 700,
                                        color: '#0099ff',
                                        textDecoration: 'none',
                                    }}>Subscribn.</Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}