import React from 'react';
import {useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {Chip, Container, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import "../App.css";
import {Helmet} from "react-helmet";
import Grid from "@mui/material/Grid";
import CustomizedProgressBar from "../components/BudgetProgress";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Ads from "../components/Ads";
import NoSubscriptions from "../components/NoSubscriptions";
import nocontentimage from "../images/nocontent.png";
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
import AnchorLink from "react-anchor-link-smooth-scroll";
import BarChartIcon from '@mui/icons-material/BarChart';
import CommentIcon from '@mui/icons-material/Comment';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import Paper from "@mui/material/Paper";
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import Avatar from "@mui/material/Avatar";
import { DiscussionEmbed } from 'disqus-react';
import SubscriptionsBanner from "../components/SubscriptionsBanner";

const useStyles = makeStyles((theme) => ({
    name: {
        lineHeight: 1,
    },
    content: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8)
        }
    },
    paragraph: {
        marginBottom: theme.spacing(3),
    },
    image: {
        maxWidth: '100%',
        borderRadius: theme.shape.borderRadius
}
}));

export default function Blog(props) {
    const classes = useStyles();

    const { subscriptionsData, currentTotalPrice, budget,  addSubscription, removeSubscription, selectedSubscriptions} = props;

    const { name } = useParams();

    const subscription = subscriptionsData.find(sub => sub.name.replace(/\s/g, "") === name);
    if (!subscription) return null;

    const similarSubscriptions = subscriptionsData.filter(sub => sub.category.replace(/\s/g, "").toLowerCase() === subscription.category.replace(/\s/g, "").toLowerCase() && sub.name != subscription.name)

    const disqusShortname = "subscribn"
    const disqusConfig = {
      url: window.location.href,
      identifier: subscription.id,
      title: subscription.name
    }

    const selectedIds = selectedSubscriptions.map(sub => sub.id)

    const addOrDelete = () => {
        if (selectedIds.includes(subscription.id)) {
            return <IconButton
                edge="end"
                aria-label="remove subscription"
                value={subscription}
                onClick={() => removeSubscription(subscription)}
                size="large"
                sx={{color: "red"}}>
                <DeleteOutlineIcon />
            </IconButton>;
        } else {
            return <IconButton
                aria-label="add subscription"
                size="large"
                onClick={() => addSubscription(subscription)}
                sx={{color: 'rgb(53,198,51)'}}>
                <AddIcon />
            </IconButton>;
        }
    }

    const paragraphs = () => subscription.description.split('\\n');

    const content = () => {
        return (
            <Box className={classes.content}>
                {paragraphs().map((i,key) => {
                    return (
                        <div>
                            {key === 2  &&
                                <Box my={4}>
                                    <a href={subscription.link} target="_blank">
                                        <img src={subscription.logo} alt={subscription.name} className={"blogimage"}
                                        style={{maxHeight: "500px", maxWidth: "350px"}}/>
                                    </a>
                                </Box>
                            }

                            {i.includes('*')? <Typography variant="h6" className={classes.paragraph}
                                                                 key={key} sx={{fontWeight: 'bold'}}>{i.replace("*", "")}</Typography>
                                    : <Typography variant="h6" color="textSecondary" paragraph={true} className={classes.paragraph}
                                                  key={key}>{i}</Typography>}
                        </div>
                );
                })}
                <Typography variant="h6" className={classes.paragraph} sx={{fontWeight: 'bold'}}>More info:</Typography>
                <Typography variant="h6" color="textSecondary" paragraph={true} className={classes.paragraph}>{subscription.information}</Typography>
            </Box>
        );
    }

    const barChart = () => {
        const data = subscriptionsData.filter(sub => sub.category === subscription.category)
            .map(subscriptionItem => {
                if(subscriptionItem.id === subscription.id)
                    return ({
                        name: subscriptionItem.name,
                        price: subscriptionItem.price,
                        fill: "rgb(44,155,36)"
                    })
                return ({
                    name: subscriptionItem.name,
                    price: subscriptionItem.price,
                    fill: "rgba(2,129,215,0.75)"
                })
                }
            );

        return (
            <>
                <Paper elevation={3} id="compareprice">
                    <Container textAlign="left" sx={{paddingTop: 3, paddingBottom: 3}}>
                    <Typography variant="h6" component="h3" color="textSecondary" className={classes.paragraph}
                                sx={{fontWeight: 'bold'}}>
                        Compare the price of {subscription.name}
                    </Typography>
                    </Container>
            <ResponsiveContainer debounce={300} width="99%" aspect={1.5}>
                <BarChart
                    data={data}
                    margin={{
                        top: 5,
                        right: 30,
                        left: 0,
                        bottom: 5
                    }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis label='£' tickCount={10} allowDecimals={false} />
                    <Tooltip contentStyle={{backgroundColor: "#0281D7BF"}} itemStyle={{ color: "black" }} />
                    <Bar dataKey="price" maxBarSize={30}/>
                </BarChart>
            </ResponsiveContainer>
                </Paper>
            </>
        );
    }


    return (
        <>
        <Helmet>
            <title>{subscription.name} - What is {subscription.name}?</title>
            <meta name="description" content={subscription.description.replace('\\n', '').replace('*', '').slice(0,160)}/>
        </Helmet>
            <section>
                <Container maxWidth="md">
                    <Box py={6}>
                        <Ads />
                        <br />
                        <Box textAlign="center" mb={3}>
                            <Container maxWidth="sm">
                                <Chip color="primary" label={"Last Updated: " + subscription.date.split('T')[0]} />
                                <Box my={4} display="flex" justifyContent="center" alignItems="center">
                                    <Avatar
                                        alt={subscription.name}
                                        src={subscription.logo}
                                        sx={{ width: 100, height: 100 }}
                                    />
                                </Box>
                                <Box my={3}>
                                    <Typography variant="h3" component="h1" color="primary">{subscription.name}</Typography>
                                </Box>
                                <Box display="flex" justifyContent="center" alignItems="center">
                                    <Box>
                                        <Typography variant="subtitle1" component="h2" className={classes.name} sx={{paddingBottom: 1}}>£{subscription.price}</Typography>
                                        <Typography variant="subtitle1" component="h3" color="textSecondary">{subscription.category}</Typography>
                                        <Typography variant="subtitle2" component="h4" color="textSecondary">{subscription.subcategories.join(' - ')}</Typography>
                                    </Box>
                                </Box>
                            </Container>
                        </Box>
                        <Grid item xs={12}>
                            <CustomizedProgressBar
                                currentTotalPrice={currentTotalPrice}
                                budget={budget}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                aria-label="link to subscriptions cost calculator"
                                href="/calculator"
                                target="_blank"
                                size="small"
                                variant="contained"
                                startIcon={<CalculateOutlinedIcon />}
                                sx={{fontWeight: 'bold', fontSize: 10}}
                            >
                                Cost calculator
                            </Button>
                            {addOrDelete()}
                            <IconButton
                                aria-label="link to website"
                                href={subscription.link}
                                target="_blank"
                                size="large"
                                color="primary">
                                <LinkIcon />
                            </IconButton>
                            <IconButton
                                aria-label="Link to charts"
                                href={subscription.link}
                                target="_blank"
                                size="large"
                            color="primary">
                                <AnchorLink href='#compareprice' style={{
                                    textDecoration: 'none',
                                    color: 'inherit'
                                }}>
                                    <BarChartIcon />
                                </AnchorLink>
                            </IconButton>
                            <IconButton
                                aria-label="Link to track subscription costs"
                                href="/costs"
                                target="_blank"
                                size="large"
                                color="primary">
                                <QueryStatsIcon />
                            </IconButton>
                            <IconButton
                                aria-label="Link to comments"
                                href={subscription.link}
                                target="_blank"
                                size="large"
                            color="primary">
                                <AnchorLink href='#comments' style={{
                                    textDecoration: 'none',
                                    color: 'inherit'
                                }}>
                                    <CommentIcon />
                                </AnchorLink>
                            </IconButton>
                        </Grid>
                        <br/>
                        <Typography variant="h6" >Similar subscriptions to {subscription.name}</Typography>
                        <SubscriptionsBanner subscriptionsData={similarSubscriptions} pauseOnHover={false} />
                        <br/>
                        {subscription.description === ""
                            ? NoSubscriptions('Sorry we are currently working on adding some more information for ' + subscription.name, nocontentimage)
                            : content()}
                     <br />
                     <Ads />
                     <br />
                     {barChart()}
                    </Box>
                    <Box className={classes.content} id="comments">
                        <Divider variant="middle"/>
                        <br />
                        <DiscussionEmbed
                            shortname={disqusShortname}
                            config={disqusConfig}
                        />
                    </Box>
                </Container>
            </section>
            <br />
            </>
    );
}
