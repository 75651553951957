import React from 'react';
import Grid from '@mui/material/Grid';
import ToggleButton from '@mui/material/ToggleButton';
import ChooseIcon from "./ChooseIcon";

export function CategoryBox(props) {

  const { onChangeCategoryValue, selected, name } = props;

  return (
        <Grid key={name} item xs={2} sm={4} md={4}>
            <ToggleButton style={{maxHeight: 100, width: "100%", height: 70, fontSize: 14, fontWeight: 'bold'}}
                          selected={selected} value={name} onChange={onChangeCategoryValue} color="primary">
                    {ChooseIcon(name)}
                    <span style={{paddingLeft: "7px"}}>{name}</span>
            </ToggleButton>
        </Grid>
  );
}

export default React.memo(CategoryBox);