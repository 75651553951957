import React from 'react';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {Container} from "@mui/material";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import "../FooterStyle.scss"
import Logo from "./Logo";
import {Link} from "react-router-dom";

export function Footer() {

    return (
        <div className="footer">
            <Divider/>
            <Container>
                <Box mt={4} />
                <Toolbar className="toolbar">
                    <Logo />
                    <Box ml="auto">
                        <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                        <Button color="inherit" size="medium">
                            Home
                        </Button>
                        </Link>
                        <Link to="/faq" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                        <Button color="inherit" size="medium">
                            FAQ
                        </Button>
                        </Link>
                    </Box>
                </Toolbar>
                <Box textAlign="center" mb={3}>
                    <Typography
                        variant="h7"
                        fontSize={14}
                        fontWeight={500}
                        color="textPrimary"
                    >
                        © Subscribn. 2023. All rights reserved
                    </Typography>
                    <br/>
                    <Typography
                        variant="p"
                        fontWeight={400}
                        fontSize={13}
                        color="textSecondary"
                    >
                        When you visit or interact with our sites, services or tools, we or
                        our authorised service providers may use cookies for storing
                        information to help provide you with a better, faster and safer
                        experience and for marketing purposes.
                    </Typography>
                </Box>
            </Container>
        </div>
    );
}

export default React.memo(Footer);