import React from "react";
import "../App.css";
import SubscriptionsPriceList from "../components/SubscriptionsPriceList";
import {Helmet} from "react-helmet";
import {Collapse, Container, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import Ads from "../components/Ads";
import Paper from "@mui/material/Paper";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { DiscussionEmbed } from 'disqus-react';

export default function SubscriptionsCalculator(props) {
  const {
    checkboxesToRender,
    subscriptionsData,
    categoryState,
    checkoutState,
    sortSubscriptions,
    searchSubscriptions,
  } = props;

  const [sortBy, setSortBy] = React.useState("default");

  const handleSortBy = (value) => {
    setSortBy(value);
  };

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const isSelected = () => {
    if(checked){
      return <ExpandLessIcon fontSize="inherit" />;
    } else {
      return <ExpandMoreIcon fontSize="inherit" />;
    }
  }

  const disqusShortname = "subscribn"
  const disqusConfig = {
      url: window.location.href,
      identifier: 'Subscription costs',
      title: 'Subscription costs'
    }

  return (
      <div className="costsPage">
        <Helmet>
          <title>Subscription costs</title>
          <meta name="description" content="View all subscription costs and monitor which subscriptions
          are increasing or decreasing in price." />
        </Helmet>
        <br/>
        <Container >
          <Ads />
          <br />
            <Paper elevation={3}>
              <Container>
                <SubscriptionsPriceList
                    subscriptions={subscriptionsData}
                    categories={categoryState.selectedCategories}
                    sortSubscriptions={sortSubscriptions}
                    searchSubscriptions={searchSubscriptions}
                    handleSortBy={handleSortBy}
                    sortBy={sortBy}
                />
              </Container>
            </Paper>
            <br/>
            <Paper elevation={3}>
            <br />
              <Typography sx={{fontWeight: 'bold'}} variant="h6" component="h2">Filter subscriptions by categories</Typography>
              <Typography sx={{opacity: '0.5', fontSize: "1"}} variant="overline" component="h3">*By default no category filters have been applied</Typography>
              <br />
            <Collapse in={checked} collapsedSize={130} sx={{paddingBottom: 5}}>
              <Container sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {checkboxesToRender}
                </Grid>
              </Container>
            </Collapse>
            <IconButton aria-label="delete" size="large" onClick={handleChange} color="primary">
              <span style={{fontWeight: 'bold', fontSize: 15}}>View all category filters </span>{isSelected()}
            </IconButton>
            <br />
            </Paper>
        </Container>
        <br />
          <Ads />
        <br />
        <Container>
            <Divider variant="middle"/>
            <br />
            <DiscussionEmbed
                shortname={disqusShortname}
                config={disqusConfig}
            />
        </Container>
        <br />
      </div>
  );
}