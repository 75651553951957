import React from 'react';
import {makeStyles} from "@mui/styles";
import image from "../images/calculate.webp";
import gridbanner from "../images/gridbackground.webp";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CalculateIcon from '@mui/icons-material/Calculate';
import AnchorLink from "react-anchor-link-smooth-scroll";
import {Link} from "react-router-dom";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import Grid from "@mui/material/Grid";
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const styles = makeStyles((theme) => ({
        heroBox: {
            width: '100%',
            display: 'flex',
            minHeight: '600px',
            alignItems: 'center',
            justifyContent: 'center',
        },
        gridContainer: {
            display: 'flex',
            alignItems: 'center',
            maxWidth: '1300px',
            padding: '50px',
        },
        largeImage: {
            width: '100%',
        },
}));

export function CalculatorBanner(props) {
    const classes = styles();

    return (

    <Box className={classes.heroBox} sx={{backgroundImage: `url(${gridbanner})`}}>
        <Grid container spacing={6} className={classes.gridContainer}>
            <Grid item xs={12} md={7}>
                <Typography variant="h3" component="h1" fontWeight={700} className={classes.title} sx={{paddingBottom: 4}}>
                    Subscriptions Cost Calculator & Tracker
                </Typography>
                <Typography variant="h6" component="h2" className={classes.subtitle} sx={{paddingBottom: 4}}>
                    Calculate your monthly subscription costs so that you can fit in the subscriptions you want instead of paying for the subscriptions you don't need!
                </Typography>
                <Link to='/calculator' style={{
                    textDecoration: 'none',
                    color: 'inherit'
                }}>
                <Button variant="contained" color="subscribn" sx={{
                    mr: 2,
                    textDecoration: 'none',
                    fontWeight: 'bold',
                }} startIcon={<CalculateIcon />}>
                        Calculate now
                </Button>
                </Link>
                <div style={{paddingBottom: 10}}/>
                <AnchorLink href='#learntouse' style={{
                    textDecoration: 'none',
                    color: 'inherit'
                }}>
                <Button variant="contained" color="subscribn" sx={{
                    mr: 2,
                    textDecoration: 'none',
                    fontWeight: 'bold',
                }} startIcon={<AutoStoriesIcon />}>
                        How to use
                </Button>
            </AnchorLink>
            <div style={{paddingBottom: 10}}/>
            <Link to='/costs' style={{
                textDecoration: 'none',
                color: 'inherit'
            }}>
            <Button variant="contained" color="subscribn" sx={{
                mr: 2,
                textDecoration: 'none',
                fontWeight: 'bold',
            }} startIcon={<QueryStatsIcon />}>
                    Track costs
            </Button>
            </Link>
            </Grid>
            <Grid item xs={12} md={5}>
                <Box>
                <img src={image} alt="My Team" className={classes.largeImage} />
                </Box>
            </Grid>
        </Grid>
    </Box>
    );
}

export default React.memo(CalculatorBanner);