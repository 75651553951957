import React, {useCallback} from "react";
import {Link} from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import {Container, Typography} from "@mui/material";
import CardActions from "@mui/material/CardActions";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ChooseIcon from "./ChooseIcon";
import Paper from "@mui/material/Paper";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {makeStyles} from "@mui/styles";
import useMediaQuery from '@mui/material/useMediaQuery';
import NoSubscriptions from "./NoSubscriptions";
import noCategories from "../images/0categories.png";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

const useStyles = makeStyles((theme) => ({
    name: {
        lineHeight: 1,
    },
    content: {
        [theme.breakpoints.up('md')]: {
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8)
        }
    },
    paragraph: {
        marginBottom: theme.spacing(3),
    },
    image: {
        maxWidth: '100%',
        borderRadius: theme.shape.borderRadius
    }
}));

export function InsightCards(props) {

    const classes = useStyles();

    const {subscriptionsData, selectedSubscriptions, currentTotalPrice} = props;

    const selectedSubscriptionsUpdated = useCallback(selectedSubscriptions.map(selectedSub =>
        subscriptionsData.find(sub => sub.id === selectedSub.id)), [selectedSubscriptions]);

    let annualPrice = (currentTotalPrice * 12).toFixed(2)

    const annualPriceEvaluator = () => {
        if (annualPrice < 500) {
            return `This is a very budget friendly cost.`;
        } else if(annualPrice > 500 && annualPrice < 1000) {
            return `Slightly high cost, try finding alternatives to your monthly subscriptions.`;
        } else if (annualPrice > 1000) {
            return `This is a very high annual cost, cut down on some subscriptions`;
        } else if(annualPrice === 0) {
            return `Add some subscriptions`;
            }
        }


    const annualCost = () => {
        return (
            <Card sx={{ width: 255, display: "flex", flexDirection: "column"}}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        #1 Insight
                    </Typography>
                    <Typography variant="h6" component="div" >
                        Annual cost
                    </Typography>
                    <Typography sx={{ mb: 1.5 }} color="primary" variant={"h5"}>
                        £{annualPrice}
                    </Typography>
                    <Typography variant="body2">
                        {annualPriceEvaluator()}
                    </Typography>
                </CardContent>
                <CardActions sx={{marginTop: "auto"}}>
                    <Button size="small" href={"/insights"}>More Insights</Button>
                </CardActions>
            </Card>
        );
    }

    const topCategory = () => {
        const showOrNot = () => {
            if(selectedSubscriptionsUpdated.length === 0){
                return <Typography color="primary" variant="h6">Add some subscriptions to find out</Typography>;
            } else {
                const occurrences = selectedSubscriptionsUpdated.map(sub => sub.category).reduce(function (acc, curr) {
                    return acc[curr] ? ++acc[curr] : acc[curr] = 1, acc
                }, {});

                let favoriteCategory = Object.keys(occurrences).reduce(function(a, b){ return occurrences[a] > occurrences[b] ? a : b });
                return(
                    <>
                        <Typography sx={{ mb: 1.5 }} color="primary" variant={"h6"}>
                            <span style={{color: "rgb(0,131,213)", verticalAlign:"middle"}}>
                                {ChooseIcon(favoriteCategory.toLowerCase())} </span>
                            {favoriteCategory}
                        </Typography>
                        <Typography variant="body1">
                            <Link to={`/category/${favoriteCategory.toLowerCase()}`} style={{
                                textDecoration: 'none',
                                color: 'inherit'
                            }}>
                                You seem to like the {favoriteCategory.toLowerCase()} category.
                                <span style={{color: "rgb(55,154,216)"}}> View all {favoriteCategory.toLowerCase()} here</span>
                            </Link>
                        </Typography>
                    </>
                );
            }
        }

        return (
            <Card sx={{ width: 255}}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        #2 Insight
                    </Typography>
                    <Typography variant="h6" component="div">
                        Favorite category
                    </Typography>
                    {showOrNot()}
                </CardContent>
            </Card>
        );
    }

    /* LARGEST COST */
    const largestCost = () => {
        const showOrNot = () => {
            if(selectedSubscriptionsUpdated.length === 0){
                return <Typography color="primary" variant="h6">Add some subscriptions to find out</Typography>;
            } else {

                const highestCostingSub = selectedSubscriptionsUpdated.reduce((max, sub) => parseFloat(max.price) > parseFloat(sub.price) ? max : sub);

                return(
                    <>
                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <Avatar alt={highestCostingSub.name} src={highestCostingSub.logo} sx={{ width: 56, height: 56 }}/>
                        </Box>
                    <Link to={`/subscription/${highestCostingSub.name.replace(/\s/g, "")}`} style={{
                        textDecoration: 'none',
                        color: 'inherit'
                    }}>
                        <Typography sx={{ mb: 1.5 }} color="primary" variant={"subtitle1"}>
                            {highestCostingSub.name}
                        </Typography>
                    </Link>
                        <Typography variant="subtitle2">
                                Monthly: £{highestCostingSub.price}
                        </Typography>
                        <Typography variant="subtitle2" >
                            Annually: £{highestCostingSub.price * 12}
                        </Typography>
                    </>
                );
            }
        }

        return (
            <Card sx={{ width: 255}}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        #3 Insight
                    </Typography>
                    <Typography variant="h6" component="div">
                        Most expensive
                    </Typography>
                    {showOrNot()}
                </CardContent>
            </Card>
        );
    }

    /* BARCHART */
    const matches = useMediaQuery('(min-width:600px)');

    const barChart = () => {
        const data = selectedSubscriptionsUpdated.map(subscriptionItem => {
            return ({
                name: subscriptionItem.name,
                price: subscriptionItem.price,
                fill: "rgba(2,129,215,0.75)"
            })
            });

        const chooseAspect = () => {
            if(matches){
                return 3;
            } else {
                return 1.5;
            }
        }

        let aspect = chooseAspect()

        return (
            <>
                <Paper elevation={3} id="compareprice">
                    <Container textAlign="left" sx={{paddingTop: 3, paddingBottom: 3}}>
                        <Typography variant="h6" component="h3" color="textSecondary" className={classes.paragraph}
                                    sx={{fontWeight: 'bold', textAlign: "left"}}>
                            Compare the price of your subscriptions
                        </Typography>
                    </Container>
                    <ResponsiveContainer debounce={300} width="99%" aspect={aspect} >
                        <BarChart
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 0,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name"  />
                            <YAxis label='£' tickCount={10} allowDecimals={false} />
                            <Tooltip contentStyle={{backgroundColor: "#0281D7BF"}} itemStyle={{ color: "black" }} />
                            <Bar dataKey="price" maxBarSize={30}/>
                        </BarChart>
                    </ResponsiveContainer>
                </Paper>
            </>
        );
    }


    return (
        <>
            <Container>
                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item>
                        {annualCost()}
                    </Grid>
                    <Grid item>
                        {topCategory()}
                    </Grid>
                    <Grid item>
                        {largestCost()}
                    </Grid>
                </Grid>
                <br />
            </Container>
            <br />
            <Container >
                {selectedSubscriptionsUpdated.length < 3
                    ? NoSubscriptions('Add more subscriptions to compare their prices', noCategories)
                    : barChart()}
            </Container>

        </>
    );
}

export default React.memo(InsightCards);