import React, {useCallback} from "react";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Grid from "@mui/material/Grid";
import CustomizedProgressBar from "./BudgetProgress";
import MoreInfo from "./MoreInfo";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import {Drawer} from "@mui/material";
import Sort from "./Sort";

const drawerHeight = "calc(40%)";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  drawer: {
    height: drawerHeight,
    flexShrink: 0,
  },
  drawerPaper: {
    height: drawerHeight,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  drawerSubHeader: {
    alignItems: "center",
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
}));

export function SubscriptionsDrawer(props) {
  const classes = useStyles();

  const {
    subscriptionsData,
    subscriptions,
    currentTotalPrice,
    removeSubscription,
    budget,
    sortSubscriptions
  } = props;


  const selectedSubscriptionsUpdated = useCallback(
      subscriptions.map(selectedSub => subscriptionsData.find(sub => sub.id === selectedSub.id)), [subscriptions]);


  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const [sortBy, setSortBy] = React.useState("default");

  const handleSortBy = (value) => {
    setSortBy(value);
  };

  const selectedSubscriptions = () => {
    return (
      <List>
        {sortSubscriptions(selectedSubscriptionsUpdated, sortBy).map((subscription) => (
          <>
            <ListItem key={subscription.id}>
              <ListItemAvatar>
                <Avatar alt={subscription.name} src={subscription.logo} />
              </ListItemAvatar>
              <ListItemText
                primary={subscription.name}
                secondary={
                  <Typography variant="body2">
                    £{subscription.price} ┃ {subscription.category.toLowerCase()} ┃
                    <MoreInfo subscription={subscription} />
                  </Typography>
                }
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="minus"
                  value={subscription}
                  onClick={() => removeSubscription(subscription)}
                  size="large">
                  <DeleteOutlineIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
            <Divider variant="middle" />
          </>
        ))}
      </List>
    );
  };

  const emptySubscriptions = () => {
    return (
      <Typography variant="h6" align="center" style={{ paddingTop: 10 }}>
        Add some subscriptions
      </Typography>
    );
  };

  const infoStrip = () => {
    return (
      <Grid item xs={6}>
        <Grid container wrap="nowrap">
          <Grid item>
            <Sort sortBy={sortBy} handleSortBy={handleSortBy} />
          </Grid>
          <Grid item>
            <Typography
              noWrap
              variant="body1"
              color="textSecondary"
              style={{ paddingTop: 12 }}
              align="center"
            >
              • &nbsp;&nbsp;{`${selectedSubscriptionsUpdated.length}`} subscriptions
            </Typography>
          </Grid>
          <Grid item>
            <Typography
                noWrap
                variant="body1"
                color="textSecondary"
                style={{ paddingTop: 12 }}
                align="center"
            >
              &nbsp;&nbsp; • &nbsp;&nbsp;Total {`£${parseFloat(currentTotalPrice).toFixed(2)}`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const drawer = () => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: true,
      })}
      role="presentation"
    >
      <div className={classes.drawerHeader}>
        <Grid container wrap="nowrap" spacing={1}>
          <Grid item xs={2} justifyContent="center" alignItems="center">
            <IconButton onClick={toggleDrawer("bottom", false)} size="large">
              <KeyboardArrowDownIcon />
            </IconButton>
          </Grid>
          <Grid item xs={7}>
            <CustomizedProgressBar
              currentTotalPrice={currentTotalPrice}
              budget={budget}
            />
          </Grid>
          <Grid item xs={3}>
            <Typography
              noWrap
              variant="body1"
              color="textSecondary"
              style={{ paddingTop: 15 }}
              align="center"
            >
              {`£${parseFloat(budget - currentTotalPrice).toFixed(2)}`} remaining
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Divider />
      {infoStrip()}
      {selectedSubscriptionsUpdated.length === 0
        ? emptySubscriptions()
        : selectedSubscriptions()}
    </div>
  );

  return (
    <React.Fragment key={"bottom"}>
      <Button onClick={toggleDrawer("bottom", true)} sx={{fontWeight: 'bold'}}>
        <Badge badgeContent={selectedSubscriptionsUpdated.length || 0} color="primary">
          {"Subscriptions"}
        </Badge>
      </Button>
      <Drawer
        className={classes.drawer}
        classes={{ paper: classes.drawerPaper }}
        anchor={"bottom"}
        open={state["bottom"]}
        onClose={toggleDrawer("bottom", false)}
      >
        {drawer()}
      </Drawer>
    </React.Fragment>
  );
}

export default React.memo(SubscriptionsDrawer);
