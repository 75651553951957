import React from 'react';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {Collapse, Container, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChooseIcon from "./ChooseIcon";
import {Link} from "react-router-dom";

export function CategoryCards(props) {
    const {categories, subscriptionsData} = props;

    const [checked, setChecked] = React.useState(false);

    const handleChange = () => {
        setChecked((prev) => !prev);
    };

    const isSelected = () => {
        if(checked){
            return <ExpandLessIcon />;
        } else {
            return <ExpandMoreIcon />;
        }
    }

    const categoryData = new Map();

    categories.map(category => categoryData.set(category, subscriptionsData.filter(sub => sub.category.toLowerCase() === category.toLowerCase()).length));

    return (
        <>
            <Container id="categories">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={6}
                >
                    <Grid item xs={7} sm={7}>
                        <Typography variant={"h4"} component="h3" sx={{paddingTop: 4, paddingBottom: 4,
                            fontWeight: 'bold', display: 'flex', justifyContent:'flex-start', fontSize: {
                                lg: 25,
                                md: 25,
                                sm: 15,
                                xs: 15
                            }}}>
                            Explore categories
                        </Typography>
                    </Grid>
                    <Grid item xs={5} sm={5} sx={{textAlign: "right"}}>
                        <Link to="/categories" style={{ color: 'inherit', textDecoration: 'inherit', paddingLeft: 15}}>
                            <Button sx={{ fontWeight: 'bold', fontSize: {
                                    lg: 14,
                                    md: 14,
                                    sm: 11,
                                    xs: 11
                                } }} variant="outlined" size="small" >View all</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
            <Container>
                <Collapse in={checked} collapsedSize={200} sx={{paddingBottom: 5}}>
                    <Grid container justifyContent="center" spacing={2} >
                        {categories.map((category) => (
                            <>
                                <Grid key={category} item name={category}>
                                    <Card sx={{ minWidth: {
                                            lg: 300,
                                            md: 200,
                                            sm: 200,
                                            xs: 150
                                        } }} key={category}>
                                        <CardContent>
                                            <Typography gutterBottom>
                                                {ChooseIcon(category)}
                                            </Typography>
                                            <Typography component="div" sx={{ fontSize: {
                                                    lg: 14,
                                                    md: 14,
                                                    sm: 14,
                                                    xs: 10
                                                }, fontWeight: 'bold' }}>
                                                {category.toUpperCase()}
                                            </Typography>
                                            <Typography sx={{ fontWeight: 'bold', fontSize: 20, color: "#14adce" }}>
                                                {categoryData.get(category)}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Link to={`/category/${category.replace(/\s/g, "").toLowerCase()}`}
                                                  style={{ color: 'inherit', textDecoration: 'inherit'}}>
                                                <Button size="small" sx={{ fontWeight: 'bold' }}>
                                                    View all
                                                </Button>
                                            </Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                </Collapse>
                <Button variant="contained" aria-label="delete" size="medium" onClick={handleChange}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                        View more categories </Typography> {isSelected()}

                </Button>
            </Container>
            <br/>
        </>
    );
}

export default React.memo(CategoryCards);