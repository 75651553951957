import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from "@mui/material/Paper";
import Sort from "./Sort";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import ScrollManager from '../ScrollManager'
import NoSubscriptions from "./NoSubscriptions";
import noCategories from "../images/0categories.png";
import noSubscriptions from "../images/0subscriptions.png";
import DescriptionTooltip from "./DescriptionTooltip";
import {InputAdornment, ListItemButton, Typography} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme) => ({
  search: {
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 5,
  },
  filter: {
    textAlign: "left"
  },
  root: {
    flexGrow: 1,
  },
  layout: {
    overflow: "auto",
  },
}));

export default function SubscriptionsPriceList(props) {
  const {
    subscriptions,
    categories,
    sortSubscriptions,
    searchSubscriptions,
    handleSortBy,
    sortBy,
  } = props;

  const classes = useStyles();

  const [searchTerm, setSearchTerm] = React.useState("");

  const handleInput = (event) => {
    setSearchTerm(event.target.value);
  };

  const checkSubscriptions = (subs) => {
    if(categories.size === 0){
      return subs.filter(
          (sub) =>
              sub
      );
    } else {
      return subs.filter(
          (sub) =>
             categories.get(sub.category.toLowerCase())
      );
    }
  };

  const content = () => {
    return (
      <List dense={true}>
        {searchSubscriptions(
          checkSubscriptions(sortSubscriptions(subscriptions, sortBy)), searchTerm
        ).map((subscription) => {
          return (
            <ListItem key={subscription.id} >
              <ListItemButton component="a" key={subscription.id} href={`/subscription/${subscription.name.replace(/\s/g, "")}`}>
              <ListItemAvatar>
                <Avatar variant="rounded" alt={subscription.name} src={subscription.logo} />
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="body2" style={{ fontWeight: 'bold' }}>{subscription.name}</Typography>}
                secondary={
                  "£" + subscription.price + " ┃ " + subscription.category.toLowerCase()
                }
              />
              </ListItemButton>
              <DescriptionTooltip item={subscription} />
              <ListItemIcon
                  edge={"end"}
                  aria-label="add"
                  value={subscription}
              >
                <IconButton href={`/subscription/${subscription.name.replace(/\s/g, "")}`}>
                  <OpenInNewIcon />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const filterStrip = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={6}
      >
        <Grid item xs className={classes.filter}>
          <Sort sortBy={sortBy} handleSortBy={handleSortBy} />
        </Grid>
        <Grid item xs className={classes.search}>
          <TextField
            id="search-box"
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleInput}
            InputProps={{
              endAdornment :
              <InputAdornment position="end">
              <IconButton
              aria-label="search icon"
              edge="end"
              >
              <SearchIcon />
              </IconButton>
              </InputAdornment>
            }}
          />
        </Grid>
      </Grid>
    );
  };


  const showSubscriptions = () => {
    if(subscriptions.length === 0){
      return NoSubscriptions("Sorry, no subscriptions right now. Try again later!", noSubscriptions);
    } else if(checkSubscriptions(sortSubscriptions(subscriptions, sortBy)).length === 0){
      return NoSubscriptions("No subscriptions left to show! Select more categories", noCategories);
    } else {
     return content();
    }
  }

  return <>
<br/>

    {filterStrip()}
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <ScrollManager scrollKey={"someid"}>
          {({ connectScrollTarget, ...props }) =>
        <Paper
          className={classes.layout}
          style={{ maxHeight: 500, minHeight: 600 }}
          ref={connectScrollTarget}
        >
          {showSubscriptions()}
        </Paper>
          }
        </ScrollManager>
      </Grid>
    </Grid>
  </>;
};