import React from "react";
import { makeStyles } from "@mui/styles";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Switch from "@mui/material/Switch";
import MenuIcon from "@mui/icons-material/Menu";
import CalculateIcon from '@mui/icons-material/Calculate';
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import HomeIcon from "@mui/icons-material/Home";
import ListAltIcon from "@mui/icons-material/ListAlt";
import InsightsIcon from '@mui/icons-material/Insights';
import { Link } from "react-router-dom";
import Brightness7Icon from "@mui/icons-material/Brightness7";
import Ads from "./Ads";
import ChooseIcon from "./ChooseIcon";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import QueryStatsIcon from '@mui/icons-material/QueryStats';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  textLink: {
    color: "inherit",
    textDecoration: "inherit",
  },
}));

export function NavigationDraw(props) {
  const { darkmode, handleSettings, categories } = props;

  const classes = useStyles();
  const [state, setState] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(open);
  };

  const getCategories = () => {
    return categories.map(category =>
        <Link
            to={`/category/${category.replace(/\s/g, "").toLowerCase()}`}
            className={classes.textLink}
            onClick={toggleDrawer(false)}
            id={category}
        >
          <ListItem key={category}>
            <ListItemIcon>
              {ChooseIcon(category)}
            </ListItemIcon>
            <ListItemText id={category} primary={category} />
          </ListItem>
        </Link>
    )
  }


  const pages = () => (
    <List
      subheader={<ListSubheader>Navigation</ListSubheader>}
      className={classes.root}
      key={"pages"}
    >
      <Link to="/" className={classes.textLink} onClick={toggleDrawer(false)} id={"home"}>
        <ListItem button key={"home"}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText id="home" primary="Home" />
        </ListItem>
      </Link>
      <Link
          to="/calculator"
          className={classes.textLink}
          onClick={toggleDrawer(false)}
          id={"subscriptions-calculator"}
      >
        <ListItem key={"subscriptions-calculator"}>
          <ListItemIcon>
            <CalculateIcon />
          </ListItemIcon>
          <ListItemText id="subscriptions" primary="Calculator" />
        </ListItem>
      </Link>
      <Link
        to="/mysubscriptions"
        className={classes.textLink}
        onClick={toggleDrawer(false)}
        id={"mysubscriptions"}
      >
        <ListItem key={"mysubscriptions"}>
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText id="subscriptions" primary="My Subscriptions" />
        </ListItem>
      </Link>
      <Link
          to="/insights"
          className={classes.textLink}
          onClick={toggleDrawer(false)}
          id={"insights"}
      >
        <ListItem key={"insights"}>
          <ListItemIcon>
            <InsightsIcon />
          </ListItemIcon>
          <ListItemText id="insights" primary="Insights" />
        </ListItem>
      </Link>
    <Link
        to="/costs"
        className={classes.textLink}
        onClick={toggleDrawer(false)}
        id={"costs"}
    >
      <ListItem key={"costs"}>
        <ListItemIcon>
          <QueryStatsIcon />
        </ListItemIcon>
        <ListItemText id="costs" primary="Costs" />
      </ListItem>
    </Link>
    </List>
  );

  const settings = () => (
    <List
      subheader={<ListSubheader>Settings</ListSubheader>}
      className={classes.root}
      key={"settings"}
    >
      <ListItem key={"mode"}>
        <ListItemIcon>
          {props.darkmode === true ? <Brightness7Icon /> : <Brightness4Icon />}
        </ListItemIcon>
        <ListItemText id="switch-list-label-darkmode" primary="Dark mode" />
        <ListItemSecondaryAction>
          <Switch
            edge="end"
            onChange={handleSettings}
            checked={darkmode}
            name="darkmode"
            inputProps={{ "aria-labelledby": "switch-list-label-darkmode" }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  );

  const categoriesList = () => (
      <List
          subheader={<ListSubheader>Categories</ListSubheader>}
          className={classes.root}
          key={"categories"}
      >
        {getCategories()}
        <Link
            to="/categories"
            className={classes.textLink}
            onClick={toggleDrawer(false)}
            id={"view all categories"}
        >
          <ListItem key={"categories"}
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete">
                        <ChevronRightIcon />
                      </IconButton>
                    }
          >
            <ListItemText id="categories" primary="View all categories"/>
          </ListItem>
        </Link>
      </List>
  );

  const navigation = () => (
    <>
      <div className={classes.drawerHeader}>
        <IconButton onClick={toggleDrawer(false)} size="large">
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      {pages()}
      {settings()}
      {categoriesList()}
      <Ads />
    </>
  );

  return (
    <div>
      <React.Fragment key={"settings draw"}>
        <IconButton
          edge="start"
          className={classes.menuButton}
          color="inherit"
          aria-label="open settings"
          onClick={toggleDrawer(true)}
          size="large">
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          className={classes.drawer}
          open={state}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {navigation()}
        </SwipeableDrawer>
      </React.Fragment>
    </div>
  );
}

export default React.memo(NavigationDraw);