import React, {useCallback} from "react";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Typography from "@mui/material/Typography";
import Sort from "../components/Sort";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import {Container, InputAdornment} from "@mui/material";
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import LinkIcon from '@mui/icons-material/Link';
import NoSubscriptions from "../components/NoSubscriptions";
import Ads from "../components/Ads";
import {Helmet} from "react-helmet";
import DescriptionTooltip from "../components/DescriptionTooltip";
import FunctionalButtons from "../components/FunctionalButtons";
import Divider from "@mui/material/Divider";
import {SnackbarProvider, useSnackbar} from "notistack";
import SearchIcon from "@mui/icons-material/Search";
import CustomizedProgressBar from "../components/BudgetProgress";

const useStyles = makeStyles((theme) => ({
  search: {
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 5,
  },
  filter: {
    textAlign: "left",
  },
  root: {
    flexGrow: 1,
    maxWidth: 160,
    minWidth: 160,
    minHeight: 275
  },
  layout: {
    overflow: "auto",
  },
}));

export default function MySubscriptions(props) {
  const {
    subscriptionsData,
    budget,
    selectedSubscriptions,
    sortSubscriptions,
    searchSubscriptions,
    removeSubscription,
    removeAllSubscriptions,
    currentTotalPrice,
  } = props;

  const classes = useStyles();

  const selectedSubscriptionsUpdated = useCallback(selectedSubscriptions.map(selectedSub =>
      subscriptionsData.find(sub => sub.id === selectedSub.id)), [selectedSubscriptions]);

  const [searchTerm, setSearchTerm] = React.useState("");

  const handleInput = (event) => {
    setSearchTerm(event.target.value);
  };

  const [sortBy, setSortBy] = React.useState("default");

  const handleSortBy = (value) => {
    setSortBy(value);
  };

  const filterStrip = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={6}
      >
        <Grid item xs={6} className={classes.filter} sm={6}>
          <Sort sortBy={sortBy} handleSortBy={handleSortBy} />
        </Grid>
        <Grid item xs={6} className={classes.search} sm={6}>
          <TextField
              id="search-box"
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleInput}
              InputProps={{
                endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                          aria-label="search icon"
                          edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
              }}
          />
        </Grid>
      </Grid>
    );
  };

  function SubscriptionCards() {
    const { enqueueSnackbar } = useSnackbar();
    return (
        <>
          <br />
        <Grid container justifyContent="center" spacing={3} >
          {searchSubscriptions(
              sortSubscriptions(selectedSubscriptionsUpdated, sortBy), searchTerm
          ).map((subscription) => (
              <>
                <Grid key={subscription.id} item name={subscription.name}>
                  <Card className={classes.root}>
                    <CardActionArea href={`/subscription/${subscription.name.replace(/\s/g, "")}`} component="a">
                      <CardMedia
                          component="img"
                          height="120"
                          image={subscription.logo}
                          title={subscription.name}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h7" component="h2" sx={{fontSize: 12}}>
                          {subscription.name}
                        </Typography>
                        <Typography variant="body2" color="textSecondary" component="p" sx={{fontSize: 12}}>
                          £{subscription.price} ┃ {subscription.category.toLowerCase()}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                    <CardActions>
                      <IconButton
                          edge="end"
                          aria-label="remove subscription"
                          value={subscription}
                          onClick={() => {removeSubscription(subscription); enqueueSnackbar(`Removed ${subscription.name}`, { variant: "error"})}}
                          size="large">
                        <DeleteOutlineIcon />
                      </IconButton>
                      <IconButton
                          edge="end"
                          aria-label="link to website"
                          href={subscription.link}
                          target="_blank"
                          size="large">
                        <LinkIcon />
                      </IconButton>
                      <DescriptionTooltip item={subscription}/>
                    </CardActions>
                  </Card>
                </Grid>
              </>
          ))}
        </Grid>
          <br/>
          <Divider variant="middle"/>
          <br/>
          <Ads />
          </>
    );
  };

  return( <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
    <Helmet>
      <title>Subscribn. - Manage all the subscriptions you have selected</title>
      <meta name="description" content="Experience the convenience of having all your selected subscriptions neatly
      organized and easily accessible from a centralized hub. Effortlessly sort and search through your subscriptions,
      empowering you to effortlessly locate the ones you need. Furthermore, with our intuitive platform, you can
      seamlessly navigate to the respective subscription sites with just a click, saving you precious time and effort.
      Additionally, take control of your subscription ecosystem by effortlessly deleting any unwanted subscriptions,
      ensuring that you only engage with the services that truly matter to you. Our comprehensive solution streamlines
      your subscription management, simplifying your digital life like never before." />
    </Helmet>
  <br />
    <Container>
      <br />
      <Grid item xs={12}>
        <CustomizedProgressBar
            currentTotalPrice={currentTotalPrice}
            budget={budget}
        />
      </Grid>
      <Grid item xs={12} >
        {FunctionalButtons(
            removeAllSubscriptions,
            budget,
            currentTotalPrice,
            "mysubscriptions"
        )}
      </Grid>
      <br />
      <Typography color={"primary"} variant="h4" component="h1" sx={{fontWeight: 'bold', paddingBottom: 2, textAlign: 'left'}}>
        My Subscriptions
      </Typography>
      <Divider variant="middle" sx={{marginBottom: 2}}/>
      {filterStrip()}
            {selectedSubscriptionsUpdated.length === 0
              ? NoSubscriptions()
              : <SubscriptionCards />}
      <br />
    </Container>
  </SnackbarProvider>);
}
