import React from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SortIcon from "@mui/icons-material/Sort"
import { Typography } from "@mui/material";

export default function Sort(props) {

  const { sortBy, handleSortBy } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value) => {
    setAnchorEl(null);
    handleSortBy(value);
  };

  return (
    <div>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="large">
        <SortIcon /> <Typography>sort</Typography>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => handleClose(sortBy)}
      >
        <MenuItem
          onClick={() => handleClose("default")}
          selected={sortBy === "default"}
        >
          Default
        </MenuItem>
        <MenuItem
          onClick={() => handleClose("alphabetic")}
          selected={sortBy === "alphabetic"}
        >
          Alphabetic
        </MenuItem>
        <MenuItem
          onClick={() => handleClose("price low")}
          selected={sortBy === "price low"}
        >
          Price low
        </MenuItem>
        <MenuItem
          onClick={() => handleClose("price high")}
          selected={sortBy === "price high"}
        >
          Price high
        </MenuItem>
      </Menu>
    </div>
  );
}
