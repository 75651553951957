import React from 'react';
import {Container, Typography} from "@mui/material";
import Ads from "../components/Ads";
import {Helmet} from "react-helmet";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Divider from "@mui/material/Divider";
import ChooseIcon from "../components/ChooseIcon";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";

export default function Categories(props) {
    const {
        subscriptionsData,
        categories
    } = props;

    const subCategories = [...new Set(subscriptionsData.flatMap(sub => sub.subcategories).map(category => category.toUpperCase()))].sort();

    const page = () => {
        return (
            <>
                <Container>
                    <br />
                    <Typography variant="h4" component="h2" sx={{fontWeight: 'bold', paddingBottom: 2}}>
                        Categories
                    </Typography>
                            <Grid container justifyContent="flex-start" spacing={2} >
                                {categories.map((category) => (
                                    <>
                                        <Grid key={category} item name={category}>
                                            <Card sx={{ minWidth: {
                                                    lg: 300,
                                                    md: 200,
                                                    sm: 200,
                                                    xs: 150
                                                } }} key={category}>
                                                <CardContent>
                                                    <Typography gutterBottom>
                                                        {ChooseIcon(category)}
                                                    </Typography>
                                                    <Typography component="div" sx={{ fontSize: {
                                                            lg: 14,
                                                            md: 14,
                                                            sm: 14,
                                                            xs: 10
                                                        }, fontWeight: 'bold' }}>
                                                        {category.toUpperCase()}
                                                    </Typography>
                                                </CardContent>
                                                <CardActions>
                                                    <Link to={`/category/${category.replace(/\s/g, "").toLowerCase()}`} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                                                        <Button size="small" sx={{ fontWeight: 'bold' }}>
                                                            View all
                                                        </Button>
                                                    </Link>
                                                </CardActions>
                                            </Card>
                                        </Grid>
                                    </>
                                ))}
                            </Grid>
                </Container>
                <br/>
                <Container>
                    <Typography variant="h4" component="h2" sx={{fontWeight: 'bold', paddingBottom: 2}}>
                        Sub categories
                    </Typography>
                    <Grid container justifyContent="flex-start" spacing={2} >
                        {subCategories.map((category) => (
                            <>
                                <Grid key={category} item name={category}>
                                    <Card sx={{ minWidth: {
                                            lg: 300,
                                            md: 200,
                                            sm: 200,
                                            xs: 150
                                        } }} key={category}>
                                        <CardContent>
                                            <Typography component="div" sx={{ fontSize: {
                                                    lg: 14,
                                                    md: 14,
                                                    sm: 14,
                                                    xs: 10
                                                }, fontWeight: 'bold' }}>
                                                {category.toUpperCase()}
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Link to={`/subcategory/${category.replace(/\s/g, "").toLowerCase()}`} style={{ color: 'inherit', textDecoration: 'inherit'}}>
                                                <Button size="small" sx={{ fontWeight: 'bold' }}>
                                                    View all
                                                </Button>
                                            </Link>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </>
                        ))}
                    </Grid>
                    <br/>
                </Container>
                <br/>
                <Divider variant="middle"/>
                <br/>
                <Ads />
            </>
        );
    }

    return (
    <>
        <Helmet>
            <title>Subscribn. - View all subscription categories in one place</title>
            <meta name="description" content="Gain valuable insights into your subscriptions by effortlessly identifying
            the ones that incur the highest costs. Unlock a wealth of knowledge through intuitive charts and captivating
            visual diagrams, transforming the task of evaluating your subscriptions into an engaging experience.
            Our innovative approach allows you to effortlessly compare subscriptions with your average cost,
            simplifying the process of discovering new subscriptions that align with your budget.
            With this comprehensive analysis at your fingertips, you can make informed decisions about
            which subscriptions to retain and which to let go, all while enjoying the thrill of exploring new options."
            />
        </Helmet>
        <br />
            {page()}
        <br />
    </>
    );
}