import React from 'react';
import { Box, Button, Container, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import {styled} from "@mui/styles";
import fourohfourimage from "../images/404.png";
import "../App.css";

const BootstrapButton = styled(Button)({
    boxShadow: 'none',
    textTransform: 'none',
    fontSize: 16,
    padding: '6px 12px',
    border: '1px solid',
    lineHeight: 1.5,
    backgroundColor: '#0063cc',
    borderColor: '#0063cc',
    fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
        backgroundColor: '#0069d9',
        borderColor: '#0062cc',
        boxShadow: 'none',
    },
    '&:active': {
        boxShadow: 'none',
        backgroundColor: '#0062cc',
        borderColor: '#005cbf',
    },
    '&:focus': {
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
    },
});

export default function Error() {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '65vh'
            }}
        >
            <Container>
                <Grid container justifyContent="center" alignItems="center" spacing={1} direction="column">
                    <Grid item>
                        <Typography  sx={{fontWeight: 'bold'}} variant="h5">
                            Page not found!
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Container maxWidth="md">
                            <img
                                src={fourohfourimage}
                                alt="Not Found"
                                loading="lazy"
                                class="fourohfourimagestyle"
                            />
                        </Container>
                    </Grid>
                    <Grid item>
                        <BootstrapButton variant="contained" href={"/"}>Back Home</BootstrapButton>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}