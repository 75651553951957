import React from 'react';
import {makeStyles} from '@mui/styles';
import AppsIcon from '@mui/icons-material/Apps';
import HomeIcon from '@mui/icons-material/Home';
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import InsightsIcon from '@mui/icons-material/Insights';
import {Link} from "react-router-dom";
import Badge from "@mui/material/Badge";
import MenuButton from "./MenuButton";

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
        },
    },
}));

export default function FunctionalButtons(removeAllSubscriptions, budget, currentTotalPrice, page,
                                          categories = [], selectedSubscriptionCount = 0, subcategories = []) {

    const classes = useStyles();

    const homePage = () => {
        return (
            <Grid className={classes.root} container justifyContent="center" style={{display: 'flex', justifyContent:'flex-start'}}>
                <Grid item>
                    <Button disabled>
                        {`£${parseFloat(budget - currentTotalPrice).toFixed(2)}`} remaining
                    </Button>
                </Grid>
                <Grid item>
                    <Link to="/mysubscriptions" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                        <Badge badgeContent={selectedSubscriptionCount || 0} color="primary">
                            <Button size="small" startIcon={<AppsIcon />} variant="text" color="primary">
                                View selected
                            </Button>
                        </Badge>
                    </Link>
                </Grid>
            </Grid>
        );
    }

    const mysubscriptions = () => {
        return (
            <Grid className={classes.root} container justifyContent="center" style={{display: 'flex', justifyContent:'flex-start'}}>
                <Grid item>
                    <Link to="/insights" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                        <Button size="small" startIcon={<InsightsIcon />}
                                color="secondary"
                                variant="outlined">
                            Insights
                        </Button>
                    </Link>
                </Grid>
                <Grid item>
                    <Button disabled>
                        Total {`£${parseFloat(currentTotalPrice).toFixed(2)}`}
                    </Button>
                </Grid>
                <Grid item>
                    <Button disabled>
                        {`£${parseFloat(budget - currentTotalPrice).toFixed(2)}`} remaining
                    </Button>
                </Grid>
            </Grid>
        );
    }

    const insights = () => {
        return (
            <Grid className={classes.root} container justifyContent="center" style={{display: 'flex', justifyContent:'flex-start'}}>
                <Grid item>
                    <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                        <Button size="small" startIcon={<HomeIcon />} variant="contained" color="primary">
                            Home
                        </Button>
                    </Link>
                </Grid>
                <Grid item>
                    <Link to="/mysubscriptions" style={{ color: 'inherit', textDecoration: 'inherit'}}>
                        <Button size="small" startIcon={<AppsIcon />} variant="contained" color="primary">
                            View selected
                        </Button>
                    </Link>
                </Grid>
                <Grid item>
                    <Button disabled size="small">
                        {`£${parseFloat(budget - currentTotalPrice).toFixed(2)}`} remaining
                    </Button>
                </Grid>
            </Grid>
        );
    }

    const categoryButtons = () => {
        return (
            <Grid className={classes.root} container justifyContent="center" style={{display: 'flex', justifyContent:'flex-start'}}>
                <Grid item>
                    <MenuButton items={categories} type={"category"} name={"Categories"}/>
                </Grid>
                <Grid item>
                    <MenuButton items={subcategories} type={"subcategory"} name={"Sub categories"}/>
                </Grid>
            </Grid>
        );
    }


    const choosePageButtons = () => {
        switch (page) {
            case 'home':
                return homePage()
            case 'insights':
                return insights()
            case 'mysubscriptions':
                return mysubscriptions()
            case 'categories':
                return categoryButtons()
            default:
                return homePage()
        }
    }

    return (
        choosePageButtons()
    );
}