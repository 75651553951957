import React from 'react';
import {Container, Typography} from "@mui/material";
import {Helmet} from "react-helmet";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    heading: {
        fontWeight: 'bold',
        paddingBottom: 2,
    },
}));

export default function FAQ() {
    const classes = useStyles();

    const page = () => {
        return (
            <>
                <Helmet>
                    <title>Subscribn. - Frequently asked questions</title>
                    <meta name="description" content="Answers to some frequently asked questions about Subscribn and
                    how it works." />
                </Helmet>
                <br/>
                <Container>
                    <Typography variant="h4" component="h2" className={classes.heading}>
                        What is Subscribn?
                    </Typography>
                    <Typography variant="h7" component="p">
                        Welcome to Subscribn, where you can conveniently explore a vast array of all
                        available subscriptions tailored to your budgetary needs. We pride ourselves on being a unique
                        destination that offers an extensive range of subscriptions, encompassing diverse categories
                        such as gaming, food, and beyond. In addition, we go the extra mile by presenting valuable
                        insights through our insightful charts, accessible on our dedicated Insights page.
                    </Typography>
                    <br/>
                    <br/>
                    <Typography variant="h4" component="h2" className={classes.heading}>
                        Are all the prices up to date?
                    </Typography>
                    <Typography variant="h7" component="p">
                        Most of the prices are up to date and regularly checked by the team but some can slip by as
                        subscription prices change very often! If you notice a price which is incorrect please feel free
                        to email us.
                    </Typography>
                    <br/>
                    <br/>
                    <Typography variant="h4" component="h2" className={classes.heading}>
                        Will you be supporting any other countries?
                    </Typography>
                    <Typography variant="h7" component="p">
                        Right now we are focusing on the United Kingdom only so that we can optimise the user
                        experience and improve it further. Once we are happy with the experience we will start looking
                        into adding more countries.
                    </Typography>
                    <br/>
                    <br/>
                    <Typography variant="h4" component="h2" className={classes.heading}>
                        How are you different from any other subscriptions website?
                    </Typography>
                    <Typography variant="h7" component="p">
                        At Subscribn, we offer an extraordinary and unparalleled experience that sets us apart from
                        other subscription sites. Our platform goes beyond the conventional concept of boxes by
                        incorporating a diverse range of categories, including streaming, tech, food, and news.
                        Not only do we provide you with an array of options, but we also empower you to manage your
                        subscriptions effectively by offering a unique budgeting feature. Additionally, our subscription
                        insights page allows you to compare and evaluate your subscriptions in a distinctive manner,
                        enabling you to make informed decisions about your memberships. With us, you'll discover a
                        one-of-a-kind subscription experience that combines variety, convenience, and insightful tools
                        to enhance your overall satisfaction.
                    </Typography>
                    <br/>
                    <br/>
                    <Typography variant="h4" component="h2" className={classes.heading}>
                        I can’t find the contact page, where is it?
                    </Typography>
                    <Typography variant="h7" component="p">
                        We are currently building the contact us page. The page will allow you to submit subscriptions
                        to us so that we can investigate, categorise and add it to the list.
                    </Typography>
                </Container>
                <br/>
            </>
        );
    }

    return (
    <>
        {page()}
    </>
    );
}