import React from 'react';
import { Container, Typography } from '@mui/material';
import nosubsimage from "../images/nosubscriptions.png";
import "../App.css";

export default function NoSubscriptions(message = "No subscriptions added, please add some to get started!", image = nosubsimage) {
    return (
        <>
            <Container
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingTop: '10px',
                }}
                alignItems="center" justifyContent="center"
            >
                <Typography  sx={{fontWeight: 'bold'}} variant="h5" align={"center"} component="h2">
                    {message}
                </Typography>
            </Container>
            <Container alignItems="center" justifyContent="center">
                <img
                    src={image}
                    alt="No Subscriptions"
                    loading="lazy"
                    className="fourohfourimagestyle"
                />
            </Container>
        </>
    );
}