import React            from 'react';
import ReactBubbleChart from 'react-bubble-chart-wi';
import "../Charts.css";
import "../../node_modules/react-bubble-chart-wi/src/style.css";
import {Container, Typography} from "@mui/material";
import NoSubscriptions from "../components/NoSubscriptions";
import Ads from "../components/Ads";
import {Helmet} from "react-helmet";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import DescriptionTooltip from "../components/DescriptionTooltip";
import AddIcon from "@mui/icons-material/Add";
import {makeStyles} from "@mui/styles";
import FunctionalButtons from "../components/FunctionalButtons";
import Divider from "@mui/material/Divider";
import {SnackbarProvider, useSnackbar} from "notistack";
import {PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, ResponsiveContainer} from "recharts";
import Paper from "@mui/material/Paper";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        maxWidth: 160,
        minWidth: 160,
        minHeight: 275
    },
}));

export default function Insights(props) {
    const {
        selectedSubscriptions,
        subscriptionsData,
        addSubscription,
        removeAllSubscriptions,
        currentTotalPrice,
        budget,
        categories
    } = props;

    const classes = useStyles();
    
    const selectedSubscriptionsUpdated = selectedSubscriptions.map(selectedSub => subscriptionsData.find(sub => sub.id === selectedSub.id))

    var colorLegend = [
        "#cb181d",
        "#cb181d",
        "#cb181d",
        "#cb181d",
        "#cb181d",
        "#005699",
        "#005699",
        "#005699",
        "#005699"
    ];

    var tooltipProps = [{
        css: 'symbol',
        prop: '_id'
    }, {
        css: 'value',
        prop: 'colorValue',
        display: 'Price'
    }, {
        css: 'change',
        prop: 'category',
        display: 'Category'
    }];

    var data = selectedSubscriptionsUpdated.map(subscription => ({
        _id: subscription.name,
        value: (subscription.price + 1.0),
        colorValue: subscription.price,
        selected: subscription.selected,
        category: subscription.category,
        href: subscription.link,
        displayText: "<div class=\"fill\" style=\"background-image: url("+subscription.logo+");\">"
    }));

    const averageCost = () => {
        let prices = selectedSubscriptionsUpdated.map(sub => Math.round(sub.price * 10) / 10)
        const sorted = Array.from(prices).sort((a, b) => a - b);
        const middle = Math.floor(sorted.length / 2);

        if (sorted.length % 2 === 0) {
            return (sorted[middle - 1] + sorted[middle]) / 2  || 0;
        }
        return sorted[middle] || 0;
    }

    const findSimilarSubscriptions = (amount) => {
        let nonSelectedSubs = subscriptionsData.filter(sub => !selectedSubscriptionsUpdated
            .find((el) => el.id === sub.id))
        let average = averageCost()
        return nonSelectedSubs.filter(sub => sub.price >= average-1.5 && sub.price <= average+1.5).slice(0, amount)
    };

    function RecommendedSubsCard() {
        const { enqueueSnackbar } = useSnackbar()

        let cardsToShow = findSimilarSubscriptions(5)

        if(cardsToShow.length === 0){
          return (
              <Typography variant="h7" sx={{fontWeight: 'bold', paddingBottom: 2}} component="h3">
                Sorry, currently none of our subscriptions fit within that price or you have already added them. 
                  Come back soon and maybe we will have some more subscriptions for you.
            </Typography>
          );
        } else {
            return (
                <Grid container justifyContent="center" spacing={3} >
                    {findSimilarSubscriptions(5).map((subscription) => (
                        <>
                            <Grid key={subscription.id} item name={subscription.name}>
                                <Card className={classes.root} key={subscription.name}>
                                    <CardActionArea href={`/subscription/${subscription.name.replace(/\s/g, "")}`} component="a">
                                        <CardMedia
                                            component="img"
                                            height="120"
                                            image={subscription.logo}
                                            title={subscription.name}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h7" component="h2" sx={{fontSize: 12}}>
                                                {subscription.name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p" sx={{fontSize: 12}}>
                                                £{subscription.price} ┃ {subscription.category.toLowerCase()}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <CardActions>
                                        <IconButton
                                            edge="end"
                                            aria-label="minus"
                                            value={subscription}
                                            onClick={() => {addSubscription(subscription); enqueueSnackbar(`Successfully added ${subscription.name}`, { variant: "info"})}}
                                            size="large">
                                            <AddIcon />
                                        </IconButton>
                                        <IconButton
                                            edge="end"
                                            aria-label="minus"
                                            href={subscription.link}
                                            target="_blank"
                                            size="large">
                                            <LinkIcon />
                                        </IconButton>
                                        <DescriptionTooltip item={subscription}/>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </>
                    ))}
                </Grid>
            );
        }
    }


    const bubbleChart = () => {
        return (
            <div style={{ width: "100vw", height: "75vh", fontWeight: 'bold', fontSize: 9}}>
            <ReactBubbleChart
                colorLegend={colorLegend}
                data={data}
                fontSizeFactor={0.3}
                legend={false}
                className="chart__bubble"
                tooltip={true}
                tooltipProps={tooltipProps}
                fixedDomain={{min: -1, max: 1}}
                mediumDiameter
            />
        </div>
        );
    }


    const radarChart = () => {
        const data = categories
            .map(category => ({
                type: category,
                count: selectedSubscriptionsUpdated.filter(item => item.category.toLowerCase() === category).length
            }));

        return (
            <Paper>
                <ResponsiveContainer debounce={300} width="99%" aspect={1.5}>
                <RadarChart cx="50%" cy="50%" outerRadius="80%" data={data}>
                    <PolarGrid />
                    <PolarAngleAxis dataKey="type" />
                    <PolarRadiusAxis />
                    <Radar name="Subscriptions" dataKey="count" stroke="#0099ff" fill="#0099ff" fillOpacity={0.6} />
                </RadarChart>
                </ResponsiveContainer>
            </Paper>
        );
    }


    const page = () => {
        return (
            <>
                <Container>
                    <Grid item xs={12} >
                        {FunctionalButtons(
                            removeAllSubscriptions,
                            budget,
                            currentTotalPrice,
                            "insights"
                        )}
                    </Grid>
                    <br/>
                </Container>
                <Container>
                    <Typography variant="h4" component="h2" sx={{fontWeight: 'bold', paddingBottom: 2}}>
                        Find your largest costing subscription
                    </Typography>
                    <Typography variant="body1" component="p">
                        By viewing this illustration below you can gain some visual insight into which subscriptions
                        are costing the most at a quick glance. The largest bubbles are the subscriptions which cost
                        the most, hover over the bubble to see more information about the subscription.
                    </Typography>
                </Container>
                {selectedSubscriptionsUpdated.length < 3
                    ? NoSubscriptions("In order to see the illustration add a few subscriptions.")
                    : bubbleChart()}
                <br/>
                <Container>
                    <Typography variant="h4" component="h2" sx={{fontWeight: 'bold', paddingBottom: 2}}>
                        The average cost of your subscriptions
                    </Typography>
                    <Typography variant="body1" component="p">
                        The average cost of one of your subscriptions is <b>£{averageCost(selectedSubscriptionsUpdated)}</b>. Here are some
                        more subscriptions that you may like within that price range:
                    </Typography>
                    <br/>
                    <RecommendedSubsCard />
                    <br/>
                </Container>
                <br/>
                <Container>
                    <Typography variant="h4" component="h2" sx={{fontWeight: 'bold', paddingBottom: 2}}>
                        Your most common categories
                    </Typography>
                </Container>
                <br/>
                <Container style={{ width: "99%", height: "100%", fontWeight: 'bold', fontSize: 10}}>
                    {selectedSubscriptionsUpdated.length < 3
                        ? NoSubscriptions("In order to see this chart add a few subscriptions.")
                        : radarChart()}
                </Container>
                <br/>
                <Divider variant="middle"/>
                <br/>
                <Ads />
            </>
        );
    }

    return (
    <>
        <Helmet>
            <title>Subscribn. - Insights into your subscriptions</title>
            <meta name="description" content="Gain valuable insights into your subscriptions by effortlessly identifying
            the ones that incur the highest costs. Unlock a wealth of knowledge through intuitive charts and captivating
            visual diagrams, transforming the task of evaluating your subscriptions into an engaging experience.
            Our innovative approach allows you to effortlessly compare subscriptions with your average cost,
            simplifying the process of discovering new subscriptions that align with your budget.
            With this comprehensive analysis at your fingertips, you can make informed decisions about
            which subscriptions to retain and which to let go, all while enjoying the thrill of exploring new options."
            />
        </Helmet>
        <br />
        <SnackbarProvider maxSnack={3} autoHideDuration={2000}>
            {page()}
        </SnackbarProvider>
        <br />
    </>
    );
}