import React, {useCallback, useEffect, useState} from "react";
import ReactGA from "react-ga4";
import "./App.css";
import TripSansBold from './fonts/TripSans-Bold.woff2';
import TripSansMedium from './fonts/TripSans-Medium.woff2';
import Home from "./pages/Home";
import NavBar from "./components/Navbar";
import CategoryBox from "./components/CategorySelector";
import useFetchSubscriptions from "./FetchSubscriptions"
import {createTheme, responsiveFontSizes, StyledEngineProvider, ThemeProvider,} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {Route, Routes} from "react-router-dom";
import MySubscriptions from "./pages/MySubscriptions";
import Insights from "./pages/Insights";
import NotFound from './pages/NotFound.js';
import FAQ from "./pages/FAQ";
import Footer from "./components/Footer";
import BlogPost from "./pages/BlogPost";
import ViewCategory from "./components/ViewCategory";
import CookieConsent from "react-cookie-consent";
import LoadingScreen from "./components/LoadingScreen";
import {useLocation} from "react-router-dom";
import Categories from "./pages/Categories";
import SubscriptionsCalculator from "./pages/SubscriptionsCalculator";
import SubscriptionPrices from "./pages/SubscriptionPrices";

function App() {
  const location = useLocation();
  useEffect(() => {
        window.scrollTo(0, 0);
      }, [location]
  );
  /* Subscriptions list */
  const {
    subscriptionsData,
    loading,
  } = useFetchSubscriptions();

  /* Budget */
  const [budget, setBudgetState] = useState(getBudget());

  const budgetHandler = (event, value) => {
    setBudgetState(value);
  };

  React.useEffect(() => {
    localStorage.setItem("budget", JSON.stringify(budget));
  }, [budget]);

  function getBudget() {
    const budget = JSON.parse(localStorage.getItem("budget"));
    if (budget === null) {
      return 50;
    } else {
      return budget;
    }
  }

  /* Max budget */
  const [maxBudget, setMaxBudgetState] = useState(getMaxBudget());

  const maxBudgetHandler = (event, value) => {
    setMaxBudgetState(value);
  };

  React.useEffect(() => {
    localStorage.setItem("maxBudget", JSON.stringify(maxBudget));
  }, [maxBudget]);

  function getMaxBudget() {
    const maxBudget = JSON.parse(localStorage.getItem("maxBudget"));
    if (maxBudget === null) {
      return 200;
    } else {
      return maxBudget;
    }
  }

  /* Category */
  const categories = [
    "streaming",
    "gaming",
    "music",
    "fashion",
    "food",
    "news and magazines",
    "boxes",
    "tech",
    "other"
  ];

  const [categoryState, setCategoryState] = useState({
    selectedCategories: getSelectedCategories(),
  });

  function getSelectedCategories() {
    const savedCategories = new Map(
        JSON.parse(localStorage.getItem("selectedCategories"))
    );
    if (savedCategories.size === 0) {
      // localStorage.setItem(
      //     "selectedCategories",
      //     JSON.stringify(allDefaultCategories)
      // );
      return savedCategories;
    } else {
      return savedCategories;
    }
  }

  const categoryHandler = (event, value) => {
    const currentBoolean = categoryState.selectedCategories.get(value);
    setCategoryState((prevState) => ({
      selectedCategories: prevState.selectedCategories.set(
          value,
          !currentBoolean
      ),
    }));
    localStorage.setItem(
        "selectedCategories",
        JSON.stringify([...categoryState.selectedCategories])
    );
  };

  const checkboxesToRender = categories.map((item) => {
    return (
        <CategoryBox
            name={item}
            selected={categoryState.selectedCategories.get(item) || false}
            onChangeCategoryValue={categoryHandler}
            key={item}
        />
    );
  });

  /* Selected subscriptions */
  const getSelectedSubscriptions = useCallback( () =>{
    const savedSubscriptions = JSON.parse(
        localStorage.getItem("selectedSubscriptions")
    );
    if (savedSubscriptions === null) {
      return [];
    } else {
      return savedSubscriptions;
    }
  }, []);

  const [checkoutState, setCheckoutState] = useState({
    selectedSubscriptions: getSelectedSubscriptions(),
  });

  React.useEffect(() => {
    localStorage.setItem(
        "selectedSubscriptions",
        JSON.stringify([...checkoutState.selectedSubscriptions])
    );
  }, [checkoutState.selectedSubscriptions]);

  const currentTotalPrice = checkoutState.selectedSubscriptions
      .map((subs) => +subs.price)
      .reduce((result, number) => result + number, 0);

  const addSubscription = useCallback((subscription) =>
      setCheckoutState((prevState) => ({
      selectedSubscriptions: prevState.selectedSubscriptions.concat(
          subscription
      )
    })), [])

  const removeSubscription =
    useCallback((subscription) =>
        setCheckoutState((prevState) => ({
      selectedSubscriptions: prevState.selectedSubscriptions.filter(
          (sub) => sub.id !== subscription.id
      ),
    })), [])

  const removeAllSubscriptions = useCallback(() =>
      setCheckoutState(() => ({
        selectedSubscriptions: [],
      })),
  [])

  /* Settings/Theming */
  const [settings, setSettings] = React.useState({
    darkmode: getInitalThemeMode(),
  });

  const handleSettings = () => {
    setSettings((prevState) => ({
      darkmode: !prevState.darkmode,
    }));
  };

  React.useEffect(() => {
    localStorage.setItem("darkmode", JSON.stringify(settings.darkmode));
  }, [settings.darkmode]);

  function getInitalThemeMode() {
    const savedMode = JSON.parse(localStorage.getItem("darkmode"));
    if (savedMode === null) {
      return false;
    } else {
      return savedMode;
    }
  }

  const palletType = settings.darkmode ? "dark" : "light";

  let theme = createTheme({
    palette: {
      mode: palletType,
      background: {
        default: palletType === 'dark' ? '#000000' : 'rgba(224,240,255,0.32)',
      },
      subscribn: {
        main: '#14adce',
        contrastText: '#ffffff',
      },
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
  });

  theme = responsiveFontSizes(theme);

  /* Functions */
  const alphabeticallySorted = useCallback((subscriptions) => {
    return Array.from(subscriptions).sort((a, b) =>
        a.name[0] > b.name[0] ? 1 : -1
    );
  }, []);

  const priceSorted = useCallback((subscriptions, type) => {
    if (type === "low") {
      return Array.from(subscriptions).sort((a, b) =>
              Math.round(a.price) > Math.round(b.price) ? 1 : -1
          );
    } else {
      return Array.from(subscriptions).sort((a, b) =>
              Math.round(a.price) < Math.round(b.price) ? 1 : -1
          );
    }
  }, []);

  const sortSubscriptions = useCallback((subscriptions, sortBy) => {
      switch (sortBy) {
        case "default":
          return subscriptions;
        case "alphabetic":
          return alphabeticallySorted(subscriptions);
        case "price low":
          return priceSorted(subscriptions, "low");
        case "price high":
          return priceSorted(subscriptions, "high");
        default:
          return subscriptions;
      }
    }, [alphabeticallySorted, priceSorted]);


  const searchSubscriptions = (subscriptions, searchTerm) => {
    if (searchTerm === "") {
      return subscriptions;
    } else {
      return subscriptions.filter((subscription) =>
          subscription.name.toLowerCase().includes(searchTerm.toLocaleLowerCase())
      );
    }
  };

  ReactGA.initialize("G-LTLM1E1LBY");

  if (loading) {
    return(
        <LoadingScreen />
    );
  } else {
    const allSubscriptionIds = subscriptionsData.map(sub => sub.id)
    checkoutState.selectedSubscriptions.forEach(selectedSub =>
       {if(!allSubscriptionIds.includes(selectedSub.id)){
          removeSubscription(selectedSub)
       }}
    )
  }

  return <>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
          <NavBar
              subscriptionsData={subscriptionsData}
              subscriptions={checkoutState.selectedSubscriptions}
              currentTotalPrice={currentTotalPrice}
              removeSubscription={removeSubscription}
              budget={budget}
              darkmode={settings.darkmode}
              handleSettings={handleSettings}
              sortSubscriptions={sortSubscriptions}
              categories={categories}
          />
          <br/>
          <Routes>
            <Route path="*" element={<NotFound/>}/>
            <Route exact path="/" element={<Home
                currentTotalPrice={currentTotalPrice}
                budgetHandler={budgetHandler}
                checkboxesToRender={checkboxesToRender}
                subscriptionsData={subscriptionsData}
                categoryState={categoryState}
                addSubscription={addSubscription}
                checkoutState={checkoutState}
                sortSubscriptions={sortSubscriptions}
                searchSubscriptions={searchSubscriptions}
                budget={budget}
                removeAllSubscriptions={removeAllSubscriptions}
                maxBudgetHandler={maxBudgetHandler}
                maxBudget={maxBudget}
                categories={categories}
            />}/>
            <Route path="/calculator" element={<SubscriptionsCalculator
                currentTotalPrice={currentTotalPrice}
                budgetHandler={budgetHandler}
                checkboxesToRender={checkboxesToRender}
                subscriptionsData={subscriptionsData}
                categoryState={categoryState}
                addSubscription={addSubscription}
                checkoutState={checkoutState}
                sortSubscriptions={sortSubscriptions}
                searchSubscriptions={searchSubscriptions}
                budget={budget}
                removeAllSubscriptions={removeAllSubscriptions}
                maxBudgetHandler={maxBudgetHandler}
                maxBudget={maxBudget}
                categories={categories}
            />}/>
            <Route path="/costs" element={<SubscriptionPrices
                checkboxesToRender={checkboxesToRender}
                subscriptionsData={subscriptionsData}
                categoryState={categoryState}
                sortSubscriptions={sortSubscriptions}
                searchSubscriptions={searchSubscriptions}
                categories={categories}
            />}/>
            <Route path="/mysubscriptions" element={<MySubscriptions
                subscriptionsData={subscriptionsData}
                budget={budget}
                selectedSubscriptions={checkoutState.selectedSubscriptions}
                sortSubscriptions={sortSubscriptions}
                searchSubscriptions={searchSubscriptions}
                removeSubscription={removeSubscription}
                removeAllSubscriptions={removeAllSubscriptions}
                currentTotalPrice={currentTotalPrice}
            />}/>
            <Route path="/insights" element={<Insights
                selectedSubscriptions={checkoutState.selectedSubscriptions}
                subscriptionsData={subscriptionsData}
                addSubscription={addSubscription}
                removeAllSubscriptions={removeAllSubscriptions}
                currentTotalPrice={currentTotalPrice}
                budget={budget}
                categories={categories}
            />}/>
            <Route path="/categories" element={<Categories
                subscriptionsData={subscriptionsData}
                categories={categories}
            />}/>
            <Route path="/faq" element={<FAQ/>}/>
            <Route path="/subscription/:name" element={<BlogPost
                subscriptionsData={subscriptionsData}
                currentTotalPrice={currentTotalPrice}
                budget={budget}
                addSubscription={addSubscription}
                removeSubscription={removeSubscription}
                selectedSubscriptions={checkoutState.selectedSubscriptions}
            />}/>
            <Route path="/category/:category" element={<ViewCategory
                subscriptionsData={subscriptionsData}
                budget={budget}
                sortSubscriptions={sortSubscriptions}
                searchSubscriptions={searchSubscriptions}
                removeAllSubscriptions={removeAllSubscriptions}
                currentTotalPrice={currentTotalPrice}
                categories={categories}
                type={"category"}
            />}/>
            <Route path="/subcategory/:category" element={<ViewCategory
                subscriptionsData={subscriptionsData}
                budget={budget}
                sortSubscriptions={sortSubscriptions}
                searchSubscriptions={searchSubscriptions}
                removeAllSubscriptions={removeAllSubscriptions}
                currentTotalPrice={currentTotalPrice}
                categories={categories}
                type={"subcategory"}
            />}/>
          </Routes>
          <CookieConsent location="bottom"
                         buttonText="Continue"
                         cookieName="subscribn_cookie"
                         style={{background: '#0099ff'}}
                         buttonStyle={{
                           color: "#4e503b",
                           fontSize: "13px",
                           background: '#ffffff',
                           fontWeight: "bolder"
                         }}
                         expires={150}>
            This website uses cookies to enhance the user experience.
          </CookieConsent>
          <Footer/>
      </ThemeProvider>
    </StyledEngineProvider>
  </>;
}

export default App;
