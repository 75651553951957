import LiveTvIcon from "@mui/icons-material/LiveTv";
import VideogameAssetIcon from "@mui/icons-material/VideogameAsset";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import DesktopMacIcon from "@mui/icons-material/DesktopMac";
import GridViewIcon from "@mui/icons-material/GridView";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import React from "react";


export default function ChooseIcon(category) {
    switch (category) {
        case 'streaming':
            return <LiveTvIcon/>
        case 'gaming':
            return <VideogameAssetIcon/>
        case 'music':
            return <HeadphonesIcon/>
        case 'fashion':
            return <CheckroomIcon/>
        case 'food':
            return <RamenDiningIcon/>
        case 'news and magazines':
            return <NewspaperIcon/>
        case 'boxes':
            return <Inventory2Icon/>
        case 'tech':
            return <DesktopMacIcon/>
        case 'other':
            return <GridViewIcon/>
        default:
            return <ArrowRightIcon/>
    }
}