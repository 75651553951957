import Typography from "@mui/material/Typography";
import React from "react";
import {makeStyles} from "@mui/styles";
import {Link} from "react-router-dom";


const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
}));

export default function Logo(props) {

    const {mode, color} = props;
    const classes = useStyles();

    return (
        <Typography component="a" className={classes.title}
                    noWrap
                    sx={{
                        mr: 2,
                        fontFamily: '"Helvetica Neue"',
                        fontWeight: 700,
                        color: '#0099ff',
                        textDecoration: 'none',
                    }}> <Link to="/" style={{ color: 'inherit', textDecoration: 'inherit'}}>Subscribn.</Link></Typography>
    )
}
