import React from "react";
import "../App.css";
import SubscriptionsList from "../components/SubscriptionsList";
import {Helmet} from "react-helmet";
import BudgetSelector from "../components/BudgetSlider";
import {Collapse, Container, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import FunctionalButtons from "../components/FunctionalButtons";
import InsightCards from "../components/InsightCards";
import Ads from "../components/Ads";
import {SnackbarProvider} from "notistack";
import Paper from "@mui/material/Paper";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { DiscussionEmbed } from 'disqus-react';
// import Recommendations from "../components/Recommendations";

export default function SubscriptionsCalculator(props) {
  const {
    currentTotalPrice,
    budgetHandler,
    checkboxesToRender,
    subscriptionsData,
    categoryState,
    addSubscription,
    checkoutState,
    sortSubscriptions,
    searchSubscriptions,
    budget,
    removeAllSubscriptions,
    maxBudgetHandler,
    maxBudget,
  } = props;

  const [sortBy, setSortBy] = React.useState("default");

  const handleSortBy = (value) => {
    setSortBy(value);
  };

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const isSelected = () => {
    if(checked){
      return <ExpandLessIcon fontSize="inherit" />;
    } else {
      return <ExpandMoreIcon fontSize="inherit" />;
    }
  }

  const disqusShortname = "subscribn"
  const disqusConfig = {
    url: window.location.href,
    identifier: 'Subscriptions cost calculator & tracker',
    title: 'Subscriptions cost calculator & tracker'
  }

  return (
      <SnackbarProvider maxSnack={4} autoHideDuration={1500}>
      <div className="calculatorPage">
        <Helmet>
          <title>Calculate your monthly subscriptions cost and track your subscriptions</title>
          <meta name="description" content="Track your subscriptions to monitor your cost and
          calculate your monthly subscriptions cost to find out which subscriptions are costing
          the most and discover new alternative subscriptions to replace costly subscriptions." />
        </Helmet>
        <br/>
        <Container >
          <Ads />
          <br />
          <Typography color={"primary"} variant="h4" component="h1" sx={{fontWeight: 'bold', paddingBottom: 2, textAlign: 'left'}}>
            Subscriptions Cost Calculator & Tracker
          </Typography>
          <Divider variant={"middle"}/>
          <br />
          <Paper elevation={3}>
            <BudgetSelector onChangeSliderValue={budgetHandler} budget={budget} maxBudgetHandler={maxBudgetHandler} maxBudget={maxBudget} />
            <Divider variant={"middle"} sx={{paddingTop: 1}} />
            <br/>
            <Typography sx={{fontWeight: 'bold'}} variant="h6" component="h2">Filter subscriptions by categories</Typography>
            <Typography sx={{opacity: '0.5', fontSize: "1"}} variant="overline" component="h3">*By default no category filters have been applied</Typography>
            <br />
            <Collapse in={checked} collapsedSize={130} sx={{paddingBottom: 5}}>
              <Container sx={{ flexGrow: 1 }}>
                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                  {checkboxesToRender}
                </Grid>
              </Container>
            </Collapse>
            <IconButton aria-label="delete" size="large" onClick={handleChange} color="primary">
              <span style={{fontWeight: 'bold', fontSize: 15}}>View all category filters </span>{isSelected()}
            </IconButton>
            <br />
            </Paper>
              <br/>
            <Paper elevation={3}>
              <Container>
                <Typography sx={{fontWeight: 'bold', paddingTop: 2}} variant="h6" component="h2">Select your subscriptions or discover new subscriptions</Typography>
                <Typography sx={{opacity: '0.5', fontSize: "1"}} variant="overline" component="h3">*Results are filtered by your budget and category selections</Typography>
                <Divider variant={"middle"} sx={{paddingTop: 1}}/>
                <SubscriptionsList
                    subscriptions={subscriptionsData}
                    budget={budget}
                    categories={categoryState.selectedCategories}
                    addSubscriptions={addSubscription}
                    selectedSubscriptions={checkoutState.selectedSubscriptions}
                    sortSubscriptions={sortSubscriptions}
                    searchSubscriptions={searchSubscriptions}
                    handleSortBy={handleSortBy}
                    sortBy={sortBy}
                    currentTotalPrice={currentTotalPrice}
                />
                <Grid item xs={12}>
                  {FunctionalButtons(
                      removeAllSubscriptions,
                      budget,
                      currentTotalPrice,
                      "home",
                      [],
                      checkoutState.selectedSubscriptions.length
                  )}
                </Grid>
              </Container>
            </Paper>
              {/*<Recommendations*/}
              {/*    subscriptions={subscriptionsData}*/}
              {/*    budget={budget}*/}
              {/*    addSubscriptions={addSubscription}*/}
              {/*    selectedSubscriptions={checkoutState.selectedSubscriptions}*/}
              {/*/>*/}
        </Container>
        <br />
        <Container>
        <Typography color={"primary"} variant="h4" component="h1" sx={{fontWeight: 'bold', paddingBottom: 2, textAlign: 'left'}}>
          Insights
        </Typography>
        <Divider variant={"middle"}/>
        </Container>
        <br/>
        <InsightCards subscriptionsData={subscriptionsData}
                      selectedSubscriptions={checkoutState.selectedSubscriptions}
                      currentTotalPrice={currentTotalPrice}
        />
        <br />
          <Ads />
        <br />
        <Container>
            <Divider variant="middle"/>
            <br />
            <DiscussionEmbed
                shortname={disqusShortname}
                config={disqusConfig}
            />
        </Container>
        <br />
      </div>
    </SnackbarProvider>
  );
}