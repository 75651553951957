import React from 'react';
import {Link, useParams} from "react-router-dom";
import {makeStyles} from "@mui/styles";
import {Container, InputAdornment, Typography} from "@mui/material";
import "../App.css";
import {Helmet} from "react-helmet";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import LinkIcon from "@mui/icons-material/Link";
import Ads from "./Ads";
import NoSubscriptions from "./NoSubscriptions";
import nocontentimage from "../images/nocontent.png";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import DescriptionTooltip from "./DescriptionTooltip";
import Divider from "@mui/material/Divider";
import FunctionalButtons from "./FunctionalButtons";
import Sort from "./Sort";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import SearchIcon from "@mui/icons-material/Search";

const useStyles = makeStyles((theme) => ({
    search: {
        textAlign: "right",
        paddingRight: 10,
        paddingTop: 5,
    },
    filter: {
        textAlign: "left",
    },
    root: {
        flexGrow: 1,
        maxWidth: 160,
        minWidth: 160,
        minHeight: 325
    },
    layout: {
        overflow: "auto",
    },
    moreInfo: {
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center'
    },
    title: {
        mr: 2,
        letterSpacing: '.2rem',
        fontWeight: 500,
        textDecoration: 'none',
    }
}));

export default function CategoryView(props) {
    const classes = useStyles();

    const {
        subscriptionsData,
        budget,
        sortSubscriptions,
        searchSubscriptions,
        removeAllSubscriptions,
        currentTotalPrice,
        categories,
        type,
    } = props;

    const { category } = useParams();

    const [searchTerm, setSearchTerm] = React.useState("");

    const handleInput = (event) => {
        setSearchTerm(event.target.value);
    };

    const [sortBy, setSortBy] = React.useState("default");

    const handleSortBy = (value) => {
        setSortBy(value);
    };

    const subCategories = [...new Set(subscriptionsData.flatMap(sub => sub.subcategories).map(category => category.toUpperCase()))].sort();

    const subscriptions = () => {
        if (type === "category") {
            return subscriptionsData.filter(sub => sub.category.replace(/\s/g, "").toLowerCase() === category);
        } else {
            return subscriptionsData.filter(sub => sub.subcategories.flatMap(subcat => subcat.replace(/\s/g, "").toLowerCase()).includes(category));
        }
    }

    const categoryName = () => {
        if (type === "category") {
            return categories.find(cate => cate.replace(/\s/g, "").toLowerCase() === category);
        } else {
            return subCategories.find(subc => subc.replace(/\s/g, "").toLowerCase() === category).toLowerCase();
        }
    }

    const headerTitle = () => {
        switch (categoryName()) {
            case 'boxes':
                return `All subscription ${categoryName()}`
            case 'flowers':
                return `All flower subscriptions`
            case 'students':
                return `All student subscriptions`
            case 'books':
                return `All book subscriptions`
            case 'ai':
                return `All AI subscriptions`
            default:
                return `All ${categoryName()} subscriptions`
        }
    }

    const headerDescription = () => {
        return <div style={{width: "80%"}}>Discover {headerTitle().toLowerCase()} in one place. Click a subscription to find out more information
            and compare the prices between subscriptions in the same category. To view all categories {<a style={{color: 'inherit'}} href="/categories">click here.</a>}</div>
    }

    if (!subscriptions) return NoSubscriptions('Sorry we are currently working on adding more subscriptions', nocontentimage);

    const filterStrip = () => {
        return (
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={6}
            >
                <Grid item xs={6} className={classes.filter} sm={6}>
                    <Sort sortBy={sortBy} handleSortBy={handleSortBy} />
                </Grid>
                <Grid item xs={6} className={classes.search} sm={6}>
                    <TextField
                        id="search-box"
                        label="Search"
                        variant="outlined"
                        size="small"
                        value={searchTerm}
                        onChange={handleInput}
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="search icon"
                                        edge="end"
                                    >
                                        <SearchIcon />
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </Grid>
            </Grid>
        );
    };

    function SubscriptionCards() {
        return (
            <>
                <Paper elevation={3}>
                <br />
                <Grid container justifyContent="center" spacing={3} >
                    {searchSubscriptions(
                        sortSubscriptions(subscriptions(), sortBy), searchTerm
                    ).map((subscription) => (
                        <>
                            <Grid key={subscription.id} item name={subscription.name}>
                                <Card className={classes.root}>
                                    <CardActionArea component="a">
                                        <Link to={`/subscription/${subscription.name.replace(/\s/g, "")}`}
                                              style={{ color: 'inherit', textDecoration: 'inherit'}}>
                                        <CardMedia
                                            component="img"
                                            height="120"
                                            image={subscription.logo}
                                            title={subscription.name}
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h7" component="h2" sx={{fontSize: 12}}>
                                                {subscription.name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p" sx={{fontSize: 12}}>
                                                £{subscription.price} ┃ {subscription.category.toLowerCase()}
                                            </Typography>
                                        </CardContent>
                                        </Link>
                                    </CardActionArea>
                                    <CardActions>
                                        <IconButton
                                            edge="end"
                                            aria-label="link to website"
                                            href={subscription.link}
                                            target="_blank"
                                            size="large">
                                            <LinkIcon />
                                        </IconButton>
                                        <DescriptionTooltip item={subscription}/>
                                    </CardActions>
                                    <Divider variant="middle" />
                                    <CardActions className={classes.moreInfo}>
                                        <Link to={`/subscription/${subscription.name.replace(/\s/g, "")}`}
                                              style={{ color: 'inherit', textDecoration: 'inherit'}}>
                                            <Button variant="outlined" size="medium" color="primary">
                                                More info
                                            </Button>
                                        </Link>
                                    </CardActions>
                                </Card>
                            </Grid>
                        </>
                    ))}
                </Grid>
                    <br />
                </Paper>
            </>
        );
    };


    return(
        <>
        <Helmet>
            <title>{headerTitle()} - Subscribn.</title>
            <meta name="description" content={`All the best ${categoryName()} subscriptions in one place. Find out which ${categoryName()} subscription you like and compare them to all the different ${categoryName()} subscriptions to find out which ones you prefer. If you are unsure which ${categoryName()} subscriptions you like, Subscribn is the perfect place to compare all ${categoryName()} subscriptions and discover new ${categoryName()} subscriptions.`} />
        </Helmet>
            <br />
            <Container>
                <Ads />
            </Container>
            <div style={{textAlign: "center"}}>
        <br />
        <Container>
            <Grid item xs={12} >
                {FunctionalButtons(
                    removeAllSubscriptions,
                    budget,
                    currentTotalPrice,
                    "categories",
                    categories,
                    0,
                    subCategories
                )}
            </Grid>
            <br/>
            <Typography variant="h3" component="h1" color="primary" className={classes.title} sx={{textAlign: 'left'}}>
                {headerTitle()}
            </Typography>
                <Typography variant="subtitle1" component="h2" sx={{textAlign: 'left', paddingTop: 3, fontWeight: 'bold'}}>
                    {headerDescription()}
                </Typography>
            <Divider variant="middle" sx={{paddingTop: 5}}/>
            <br/>
            {filterStrip()}
            {subscriptions().length === 0
                ? NoSubscriptions('Sorry we are currently working on adding more subscriptions', nocontentimage)
                : <SubscriptionCards />}
            <br/>
            <Ads />
            <br />
        </Container>
            </div>
        </>
            );
}