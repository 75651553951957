import {Container, Typography} from "@mui/material";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import InsightsIcon from "@mui/icons-material/Insights";
import React from "react";


export function InsightsHomepage() {

    return (
        <Container sx={{textAlign: 'left'}}>
            <Typography variant={"h4"} component="h3" sx={{
                paddingTop: 4,
                paddingBottom: 4,
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'flex-start'
            }}>
                Insights
            </Typography>
            <Divider variant="middle"/>
            <Typography variant={"body1"} component="h4"
                        sx={{paddingTop: 4, fontWeight: 'bold', display: 'flex', justifyContent: 'flex-start'}}>
                You might be thinking what are insights and how do they benefit me? Insights are crucial to
                understanding your subscriptions. At Subscribn we give you
                a full breakdown of your subscriptions in a unique way, which currently consist of the following:
            </Typography>
            <List>
                <ListItem>
                    <ListItemText
                        primary="Largest costing subscriptions"
                        secondary="By viewing the insights page you will see your largest costing subscriptions in a
                      unique way. Go to the Insights page and be amazed by the bubble diagram."
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="Average cost of your subscriptions"
                        secondary="Sometimes as humans we have a pattern of choosing things which cost a similar amount, so
                  what we have done at Subscribn is taken your average cost and compared it to all our subscriptions
                  in order to give you subscriptions which cost a similar amount. Feel free to add these subscription
                  recommendations to your list."
                    />
                </ListItem>
                <ListItem>
                    <ListItemText
                        primary="Favourite categories"
                        secondary="You may want to widen your horizons by subscribing to new forms of subscriptions. By looking
                  at the graph presented to you, you will be able to see which categories you love the most and then return
                  to the homepage to filter out those categories to find something new."
                    />
                </ListItem>
            </List>
            <Typography variant={"body1"} component="h4"
                        sx={{paddingBottom: 3, fontWeight: 'bold', display: 'flex', justifyContent: 'flex-start'}}>
                Go view your Insights now
            </Typography>
            <Link to="/insights" style={{color: 'inherit', textDecoration: 'inherit'}}>
                <Button size="small" startIcon={<InsightsIcon/>}
                        color="secondary"
                        variant="contained">
                    Insights
                </Button>
            </Link>
        </Container>
    );
}

export default React.memo(InsightsHomepage);