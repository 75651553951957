import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import ListItemIcon from '@mui/material/ListItemIcon';
import Paper from "@mui/material/Paper";
import Sort from "./Sort";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import ScrollManager from '../ScrollManager'
import NoSubscriptions from "./NoSubscriptions";
import noBudget from "../images/0budget.png";
import noCategories from "../images/0categories.png";
import noSubscriptions from "../images/0subscriptions.png";
import DescriptionTooltip from "./DescriptionTooltip";
import {InputAdornment, ListItemButton, Typography} from "@mui/material";
import {useSnackbar} from "notistack";
import CustomizedProgressBar from "./BudgetProgress";
import SearchIcon from '@mui/icons-material/Search';

const useStyles = makeStyles((theme) => ({
  search: {
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 5,
  },
  filter: {
    textAlign: "left"
  },
  root: {
    flexGrow: 1,
  },
  layout: {
    overflow: "auto",
  },
}));

export default function SubscriptionsList(props) {
  const {
    subscriptions,
    budget,
    categories,
    addSubscriptions,
    selectedSubscriptions,
    sortSubscriptions,
    searchSubscriptions,
    handleSortBy,
    sortBy,
    currentTotalPrice,
  } = props;

  const classes = useStyles();

  const [searchTerm, setSearchTerm] = React.useState("");

  const { enqueueSnackbar } = useSnackbar()

  const handleInput = (event) => {
    setSearchTerm(event.target.value);
  };

  const checkIfSubscriptionSelected = (sub) => {
    return !!selectedSubscriptions.find((el) => el.id === sub.id);
  };

  const checkSubscriptions = (subs) => {
    if(categories.size === 0){
      return subs.filter(
          (sub) =>
              sub.price < budget &&
              !checkIfSubscriptionSelected(sub)
      );
    } else {
      return subs.filter(
          (sub) =>
              sub.price < budget &&
              categories.get(sub.category.toLowerCase()) &&
              !checkIfSubscriptionSelected(sub)
      );
    }
  };

  const content = () => {
    return (
      <List dense={true}>
        {searchSubscriptions(
          checkSubscriptions(sortSubscriptions(subscriptions, sortBy)), searchTerm
        ).map((subscription) => {
          return (
            <ListItem key={subscription.id} >
              <ListItemButton component="a" key={subscription.id} href={`/subscription/${subscription.name.replace(/\s/g, "")}`}>
              <ListItemAvatar>
                <Avatar variant="rounded" alt={subscription.name} src={subscription.logo} />
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="body2" style={{ fontWeight: 'bold' }}>{subscription.name}</Typography>}
                secondary={
                  "£" + subscription.price + " ┃ " + subscription.category.toLowerCase()
                }
              />
              </ListItemButton>
              <DescriptionTooltip item={subscription} />
              <ListItemIcon
                  edge={"end"}
                  aria-label="add"
                  value={subscription}
                  onClick={() => {addSubscriptions(subscription); enqueueSnackbar(`Successfully added ${subscription.name}`, { variant: "info"})}}
              >
                <IconButton>
                  <AddIcon />
                </IconButton>
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>
    );
  };

  const filterStrip = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={6}
      >
        <Grid item xs className={classes.filter}>
          <Sort sortBy={sortBy} handleSortBy={handleSortBy} />
        </Grid>
        <Grid item xs className={classes.search}>
          <TextField
            id="search-box"
            label="Search"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleInput}
            InputProps={{
              endAdornment :
              <InputAdornment position="end">
              <IconButton
              aria-label="search icon"
              edge="end"
              >
              <SearchIcon />
              </IconButton>
              </InputAdornment>
            }}
          />
        </Grid>
      </Grid>
    );
  };


  const showSubscriptions = () => {
    if(subscriptions.length === 0){
      return NoSubscriptions("Sorry, no subscriptions right now. Try again later!", noSubscriptions);
    } else if(budget === 0) {
      return NoSubscriptions("Increase your budget to see some subscriptions", noBudget);
    } else if(checkSubscriptions(sortSubscriptions(subscriptions, sortBy)).length === 0){
      return NoSubscriptions("No subscriptions left to show! Select more categories", noCategories);
    } else {
     return content();
    }
  }

  return <>
<br/>

    {filterStrip()}
    <Grid container direction="row" justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <ScrollManager scrollKey={"someid"}>
          {({ connectScrollTarget, ...props }) =>
        <Paper
          className={classes.layout}
          style={{ maxHeight: 500, minHeight: 400 }}
          ref={connectScrollTarget}
        >
          {showSubscriptions()}
        </Paper>
          }
        </ScrollManager>
      </Grid>
    </Grid>
    <Grid item xs={12} sx={{paddingBottom: 1}}>
      <CustomizedProgressBar
          currentTotalPrice={currentTotalPrice}
          budget={budget}
      />
    </Grid>
  </>;
};