import React from 'react';
import AdSense from "react-adsense";
import Media from 'react-media';
import "../App.css";

export default function Ads() {

    return (
        <div style={{textAlign: "center"}}>
            <Media query={{ minWidth: 250 }}>
                {matches =>
                    matches ? (
                            <AdSense.Google
                                client="ca-pub-6313626840376086"
                                slot="7628969461"
                                style={{ display: "block" }}
                                layout="in-article"
                                format="fluid"
                            />
                    ) : (
                        <></>
                    )
                }
            </Media>
        </div>
    );
}