import React from 'react';
import { withStyles } from "@mui/styles";
import Slider from '@mui/material/Slider';
import {Container, Typography} from '@mui/material'
import Grid from '@mui/material/Grid';
import TextField from "@mui/material/TextField";

const BudgetSlider = withStyles({
  root: {
    color: '#0099ff',
    height: 8,
  },
  thumb: {
    height: 26,
    width: 26,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 10,
    borderRadius: 6,
  },
  rail: {
    height: 10,
    borderRadius: 6,
  },
})(Slider);


export default function BudgetSelector(props) {

  const { onChangeSliderValue, budget, maxBudgetHandler, maxBudget } = props;

  return (
      <>
        <br/>
  <Grid container direction="row" justifyContent="center" alignItems="center">
      <Container>
          <Typography sx={{fontWeight: 'bold'}} variant="h6" component="h2">What is your monthly budget?</Typography>
          <Typography sx={{opacity: '0.7', fontSize: "1"}} variant="overline" component="h3">Move slider or enter number to select your monthly budget</Typography>
      </Container>
  </Grid>
          <br/>
          <br/>
    <Grid container direction="row" justifyContent="center">
        <Grid item xs={10}>
            <BudgetSlider aria-label="budget slider" defaultValue={budget} valueLabelDisplay='on'
            valueLabelFormat={value => <div>{'£' + value}</div>} 
            onChangeCommitted={onChangeSliderValue}
            max={maxBudget}
            key={`slider-${budget}`}
            />
        </Grid>
    </Grid>
    <Grid container direction="row" justifyContent="center">
        <Grid item xs={5} style={{ flexGrow: "1" }}>
          <TextField label="Max budget" color="primary" value={maxBudget} size="small" focused type="number"
                     onChange={(event) => {
                         if (event.target.value === ""){
                             maxBudgetHandler(event, 0); onChangeSliderValue(event, 0)
                         } else {
                             maxBudgetHandler(event, parseInt(event.target.value)); onChangeSliderValue(event, parseInt(event.target.value))
                         }
                             }}
          />
        </Grid>
    </Grid>
          <br/>
      </>
  );
}