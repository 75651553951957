import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import Box from '@mui/material/Box';
import {SubscriptionsDrawer} from "./SubscriptionsDraw";
import { makeStyles } from '@mui/styles';
import NavigationDraw from './NavigationDraw';
import Logo from "./Logo";
import {Container} from "@mui/material";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({ target: window ? window() : undefined });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export function NavBar(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <React.Fragment>
        <CssBaseline />
        <Box m={2} mb={4}>
          <HideOnScroll {...props}>
            <AppBar color='inherit' elevation={2}>
              <Container>
              <Toolbar>
                <NavigationDraw {...props} />
                <Logo />
                <SubscriptionsDrawer {...props} />
              </Toolbar>
              </Container>
            </AppBar>
          </HideOnScroll>
        </Box>
      </React.Fragment>
    </div>
  );
}

export default React.memo(NavBar);