import { useEffect, useState} from 'react';
import axios from 'axios';

const useFetchSubscriptions = () => {
    const [subscriptionsData, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getBaseUrl = () => {
        let url;
        switch(process.env.NODE_ENV) {
            case 'production':
                url = 'https://subscribn.com'; //for local use (url = 'http://0.0.0.0';)
                break;
            case 'development':
            default:
                url = 'https://subscribn.com';
        }

        return url;
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data: response } = await axios.get(getBaseUrl() +`/subscriptions/?format=json`);
                setData(response);
            } catch (error) {
                console.error(error)
            }
            setLoading(false);
        };

        fetchData();

    }, []);

    return {
        subscriptionsData,
        loading,
    };
};

export default useFetchSubscriptions;