import React from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import {LinearProgress, Typography} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#32CD32',
  },
}))(LinearProgress);

const BudgetFailed = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#C41E3A',
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

export default function CustomizedProgressBar(props) {
  const classes = useStyles();

  const { currentTotalPrice, budget } = props;

  function normalise(value) {
    if(value > budget){
      return budget * 100 / budget;
    } else {
      return (value - 0) * 100 / (budget - 0);
    }
  }

  const budgetFailed = () => {
    return (
        <div>
        <BudgetFailed variant="determinate" value={100} />
        <Typography variant="subtitle1" align="center" style={{ paddingTop: 10, fontWeight: "bold" }} >
         <CancelIcon fontSize="inherit" style={{verticalAlign:"middle"}}/>  Budget exceeded
        </Typography>
        </div>
    );
  };

  return (
    <div className={classes.root} style={{paddingTop: 20}}>
      {currentTotalPrice > budget
          ? budgetFailed()
          : <BorderLinearProgress variant="determinate" value={normalise(currentTotalPrice)} />
      }
    </div>
  );
}