import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import LinkIcon from '@mui/icons-material/Link';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { ListItemIcon } from '@mui/material';
import Link from '@mui/material/Link';
import ArticleIcon from '@mui/icons-material/Article';

export default function MoreInfo(props) {

  const { subscription } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return <>
        <IconButton
            aria-controls="fade-menu"
            aria-haspopup="true"
            onClick={handleClick}
            color='primary'
            size="large">
          <MoreHorizIcon />
        </IconButton>
        <Menu
            id="fade-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
        >
        <Link target="_blank" href={subscription.link} style={{ textDecoration: 'none' }} color="inherit">
            <MenuItem onClick={handleClose}>
            <ListItemIcon>
                <LinkIcon style={{ color: '#02a0c7' }} /> 
            </ListItemIcon>
                Website
            </MenuItem>
        </Link>
        <Link target="_blank" href={`/subscription/${subscription.name.replace(/\s/g, "")}`} style={{ textDecoration: 'none' }} color="inherit">
            <MenuItem onClick={handleClose}>
                <ListItemIcon>
                    <ArticleIcon style={{ color: '#02a0c7' }} />
                </ListItemIcon>
                Details
            </MenuItem>
        </Link>
      </Menu>
        </>;
}